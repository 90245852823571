<template>
  <div v-if="!isLoading" class="hub-leaderboard">
    <div class="hub-leaderboard__header">
      <h2 v-if="isShowTitle" class="hub-leaderboard__heading">
        {{ ratingData.rating.name }}
      </h2>
      <button
        v-if="isShowMoreButton"
        class="hub-leaderboard__button"
        @click="handleMoreButtonClick"
      >
        {{ moreButtonText }}
        <Icon
          class="hub-leaderboard__chevron"
          :class="modifyClassChevron"
          :size="16"
          name="chevron"
        />
      </button>
    </div>
    <div v-if="currentRows.length" class="hub-leaderboard__table">
      <Table>
        <template #thead>
          <LeaderboardTableHead :structure="ratingData.structure" />
        </template>
        <template #tbody>
          <LeaderboardTableRow
            v-if="user"
            :structure="ratingData.structure"
            :columns="user"
            is-highlight
          />
          <LeaderboardTableRow
            v-for="row in currentRows"
            :key="row.place"
            :structure="ratingData.structure"
            :columns="row"
          />
        </template>
      </Table>
    </div>
    <HubContentMessage v-else :text="$t('global.noData')" />
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Table from '@components/v2/hub/leaderboard/table/Table.vue';
import LeaderboardTableHead from '@components/v2/hub/leaderboard/LeaderboardTableHead.vue';
import LeaderboardTableRow from '@components/v2/hub/leaderboard/LeaderboardTableRow.vue';
import HubContentMessage from '@components/v2/hub/HubContentMessage.vue';

export default {
  name: 'HubLeaderboard',
  components: {
    Icon,
    Table,
    LeaderboardTableHead,
    LeaderboardTableRow,
    HubContentMessage,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isShowAll: false,
      isShowMoreButton: false,
      perPage: 10,
      currentPage: 0,
      ratingData: [],
      rows: [],
    };
  },
  computed: {
    user() {
      if (this.ratingData.currentUserRatingItem) {
        return this.ratingData.currentUserRatingItem;
      }
      return null;
    },
    currentRows() {
      if (this.isShowAll) {
        return this.rows;
      }

      return this.rows.slice(0, this.perPage);
    },
    moreButtonText() {
      return this.isShowAll
        ? this.$t('change.hide')
        : this.$t('actions.show_more');
    },

    modifyClassChevron() {
      return this.isShowAll ? 'hub-leaderboard__chevron--rotate' : '';
    },

    isShowTitle() {
      return this.ratingData.rating.name || false;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchRating();
      this.isShowMoreButton = this.ratingData.pagen.pagesCount > 1;
      this.isLoading = false;
    },

    async fetchRating() {
      const queryParams = {
        params: {
          type: 'hub',
          pageSize: this.perPage,
          pageNum: this.currentPage,
        },
      };

      this.ratingData = await api.get(
        `rating/${this.id}`,
        queryParams,
      );

      this.rows.push(...this.ratingData.items);
    },
    async fetchRatingRecursive() {
      if (!this.ratingData.items.length) {
        this.isShowMoreButton = true;
        return;
      }
      this.currentPage += 1;
      await this.fetchRating();
      this.fetchRatingRecursive();
    },

    handleMoreButtonClick() {
      this.isShowAll = !this.isShowAll;
      this.fetchRatingRecursive();
    },
  },
};
</script>

<style lang="scss" scoped>
.hub-leaderboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 4px;
}
.hub-leaderboard__heading {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
}
.hub-leaderboard__button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: #16a7ff;
  background: none;
  border: none;
  flex-shrink: 0;
}
.hub-leaderboard__chevron {
  &--rotate {
    transform: rotate(90deg) translateX(2px);
  }
}
.hub-leaderboard__table {
  width: 100%;
  overflow: auto;
}
</style>
