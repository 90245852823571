<template>
  <div v-if="!loading" class="tab-content-leaderboard">
    <div class="tab-content-leaderboard__tablist">
      <HubTabList
        v-model="currentTab"
        :tab-key="tablist.tabKey"
        :tabs="tablist.tabs"
        @input="getRatings"
      />
    </div>
    <ul
      v-if="leaderboards.length"
      class="tab-content-leaderboard__list"
    >
      <li
        v-for="leaderboard in leaderboards"
        :key="leaderboard.id"
        class="tab-content-leaderboard__list-item"
      >
        <HubLeaderboard :id="leaderboard.id" />
      </li>
    </ul>
    <HubContentMessage v-else :text="$t('global.noData')" />
  </div>
</template>

<script>
import HubTabList from '@components/v2/hub/HubTabList.vue';
import HubLeaderboard from '@components/v2/hub/HubLeaderboard.vue';
import HubContentMessage from '@components/v2/hub/HubContentMessage.vue';
import { LeaderboardTabs } from './const.js';

export default {
  name: 'TabContentLeaderboard',
  components: {
    HubTabList,
    HubLeaderboard,
    HubContentMessage,
  },
  data() {
    return {
      loading: true,
      currentTab: LeaderboardTabs[1],
      leaderboards: [],
    };
  },
  computed: {
    hubId() {
      return this.$route.params.id;
    },
    tablist() {
      return {
        tabKey: 'type',
        tabs: LeaderboardTabs,
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getRatings();
      if (!this.leaderboards.length) {
        this.currentTab = LeaderboardTabs[0];
        await this.getRatings();
      }
      this.loading = false;
    },
    async getRatings() {
      switch (this.currentTab.type) {
        case 'current':
          await this.getRatingsCurrent();
          break;
        case 'finished':
          await this.getRatingsFinished();
          break;
        default:
          await this.getRatingsDefault();
          break;
      }
    },

    fetchRatings(type, params = {}) {
      const queryParams = {
        params,
      };
      return api.get(
        `tournament/${this.hubId}/rating/${type}`,
        queryParams,
      );
    },

    async getRatingsDefault() {
      const response = await this.fetchRatings('default');
      const rating = { id: response.data.id };
      this.setLeaderboards([rating]);
    },
    async getRatingsCurrent() {
      const response = await this.fetchRatings('season', {
        status: 'current',
      });
      const ratings = [];
      const [ratingData] = response.data;
      if (ratingData) {
        ratings.push({ id: ratingData.id });
      }
      this.setLeaderboards(ratings);
    },
    async getRatingsFinished() {
      const response = await this.fetchRatings('season', {
        status: 'finished',
      });
      const ratings = [];
      response.data.forEach((rating) => {
        ratings.push({ id: rating.id });
      });
      this.setLeaderboards(ratings);
    },

    setLeaderboards(ratings) {
      this.leaderboards = ratings;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content-leaderboard__tablist {
  margin-bottom: 16px;
}
.tab-content-leaderboard__list {
  list-style-type: none;
}
.tab-content-leaderboard__list-item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
.tab-content-leaderboard__message {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  background: #2d2d30;
  padding: 20px 12px;
  border-radius: 6px;
}
</style>
