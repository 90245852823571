<template>
  <loader v-if="loadingState.page" class="page-loader" />
  <div v-else class="rating-table">
    <template v-if="rating || playerRating || hubRating.length">
      <bracket-search
        v-if="!isHub"
        :tournament-id="id"
        :placeholder="$t('tournaments.tableSearch')"
        @input="updateRatingTable"
      />

      <div ref="box" class="table-wrapper">
        <loader v-if="loadingState.tournamentRating" :size="64" />

        <RatingTable
          v-if="!hasPlayersRating"
          :active-index="teamIndex.current"
          :has-penalty="hasPenalty"
          :rating="rating"
        />

        <HubLeaderBoardTable
          v-if="isHub && hubRating.length"
          :rating="hubRating"
          :current-user-hash="getCurrentProfileHash"
        />

        <RatingPlayersTable
          v-else
          :pagination="pagination"
          :active-index="teamIndex.current"
          :rating="playerRating"
        />
      </div>
      <pvp-pagination
        :params="pagination"
        :disabled="loadingState.tournamentRating"
        @onchange="setPage"
      />
    </template>
    <div v-else class="error">
      {{ $t('tournaments.placeholders_table') }}
    </div>
  </div>
</template>

<script>
import RatingTable from '@components/RatingComponents/RatingTable.vue';
import RatingPlayersTable from '@components/RatingComponents/RatingPlayersTable.vue';
import BracketSearch from '@components/TournamentComponents/Brackets/BracketUI/BracketSearch.vue';
import HubLeaderBoardTable from '@components/RatingComponents/HubLeaderBoardTable.vue';
import { fetchRatingById } from '@src/shared/api/rating';
import { mapGetters } from 'vuex';

export default {
  name: 'TournamentRatingTable',
  components: {
    RatingTable,
    BracketSearch,
    RatingPlayersTable,
    HubLeaderBoardTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    hub: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loadingState: {
      tournamentRating: true,
      hubRating: false,
    },
    rating: null,

    playerRating: null,

    teamIndex: {
      list: 0,
      current: 0,
    },

    hubRating: [],

    pagination: {
      page: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],
    },
  }),
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('tournaments', ['getIsHub']),
    ...mapGetters('profile', ['getCurrentProfileHash']),
    ...mapState('application', ['scssContext']),

    hubRatingInfo() {
      return this.hub?.ratings;
    },

    isHub() {
      return this.getIsHub(this.id);
    },

    currentHubRatingId() {
      return this.hubRatingInfo[0]?.id;
    },

    hasPenalty() {
      return this.getTournament(this.id)?.hasPenalty;
    },
    hasPlayersRating() {
      // проверяем есть ли у турнира флаг, чтообы выводить другую таблицу
      return this.getTournament(this.id)?.withPlayerKillsRating;
    },
  },
  created() {
    // если есть hasPlayersRating то запрашиваем другие данные для вывода рейтинга
    if (this.hasPlayersRating) {
      this.loadPlayerTornamentRatingTable();
    } else if (this.isHub) {
      this.loadHubRating();
    } else {
      this.loadTournamentRatingTable();
    }
  },

  methods: {
    ...mapActions('tournaments', [
      'fetchTournamentTable',
      'fetchTournamentPlayersTable',
    ]),

    setPage({ page, perPage }) {
      this.pagination.page = page;
      this.pagination.perPage = perPage;
      if (this.hasPlayersRating) {
        this.loadPlayerTornamentRatingTable();
      } else if (this.isHub) {
        this.loadHubRating();
      } else {
        this.loadTournamentRatingTable();
      }
    },

    updateRatingTable(teamIndex) {
      this.teamIndex.current = -1;
      this.teamIndex.list = teamIndex;
      if (teamIndex) {
        const { perPage } = this.pagination;
        this.setPage({
          page: Math.ceil(teamIndex / perPage).toFixed(0),
          perPage,
        });
      }
    },
    // fetch рейтинг хаба
    async loadHubRating() {
      const responseData = await fetchRatingById({
        pageNum: this.pagination.page,
        pageSize: this.pagination.perPage,
        id: this.currentHubRatingId,
      });

      this.hubRating = responseData.rating.items;
      this.pagination.total = responseData.pagination.total;
      this.loadingState.hubRating = false;
      this.loadingState.page = false;
      this.loadingState.tournamentRating = false;
    },

    // заппрос на получение данных для hasPlayersRating
    loadPlayerTornamentRatingTable: function () {
      this.loadingState.tournamentRating = true;
      const { page, perPage } = this.pagination;
      return this.fetchTournamentPlayersTable({
        page,
        limit: perPage,
        id: this.id,
      })
        .then((data) => {
          this.playerRating = data.rating;
          this.pagination.total = data.pagination.total;
          this.loadingState.tournamentRating = false;
          this.loadingState.page = false;
        })
        .catch(() => {
          this.rating = null;
        })
        .finally(() => {
          this.loadingState.tournamentRating = false;
          this.loadingState.page = false;

          this.teamIndex.current =
            this.teamIndex.list - (page - 1) * perPage - 1;

          this.$nextTick(() => {
            const { box } = this.$refs;
            const activeRow = box?.querySelector('.table-row.active');
            if (activeRow) {
              const { headerHeight } = this.scssContext;
              window.scrollTo({
                top:
                  window.pageYOffset +
                  activeRow.getBoundingClientRect().top -
                  headerHeight,
              });
            }
          });
        });
    },

    loadTournamentRatingTable: _.debounce(function () {
      this.loadingState.tournamentRating = true;
      const { page, perPage } = this.pagination;
      return this.fetchTournamentTable({
        page,
        limit: perPage,
        id: this.id,
      })
        .then((data) => {
          this.rating = data.rating;
          this.pagination.total = data.pagination.total;
        })
        .catch(() => {
          this.rating = null;
        })
        .finally(() => {
          this.loadingState.tournamentRating = false;
          this.loadingState.page = false;

          this.teamIndex.current =
            this.teamIndex.list - (page - 1) * perPage - 1;

          this.$nextTick(() => {
            const { box } = this.$refs;
            const activeRow = box?.querySelector('.table-row.active');
            if (activeRow) {
              const { headerHeight } = this.scssContext;
              window.scrollTo({
                top:
                  window.pageYOffset +
                  activeRow.getBoundingClientRect().top -
                  headerHeight,
              });
            }
          });
        });
    }, 600),
  },
};
</script>

<style lang="scss" scoped>
.rating-table {
  margin-top: 20px;
  @include max-tablet() {
    .rating-table {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.bracket-search {
  margin-bottom: 20px;
  z-index: 3;
  position: relative;
  @include max-tablet() {
    width: calc(100% - #{24});
    margin-left: 12px;
  }
  @include min-tablet() {
    max-width: 334px;
  }
}

.table-wrapper {
  position: relative;

  .loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    align-items: center;
    background-color: rgba($dark, 0.8);
  }
}

.page-loader {
  margin-top: 20px;
}

.pagination {
  margin-top: 30px;
}

.error {
  text-align: center;
}
</style>
