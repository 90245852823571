<template>
  <loader v-if="loadingState.bracket" />
  <div v-else-if="hasBracket" ref="bracket">
    <div class="bracket-view">
      <component
        :is="bracketType"
        v-if="bracketType"
        :tournament-id="id"
        :rounds="rounds"
      />
    </div>
  </div>
  <p v-else class="error">
    {{ $t('tournaments.placeholders_bracket') }}
  </p>
</template>

<script>
import Single from '@components/TournamentComponents/Brackets/SingleElimination.vue';
import Double from '@components/TournamentComponents/Brackets/DoubleElimination.vue';
import GroupStageTable from '@components/TournamentComponents/Brackets/GroupStageTable.vue';
import TeamFFA from '@components/TournamentComponents/Brackets/TeamFFA.vue';
import SwissTable from '@components/TournamentComponents/Brackets/SwissTable';

export default {
  components: {
    Single,
    Double,
    TeamFFA,
    GroupStageTable,
    SwissTable,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      bracket: true,
    },
    bracketType: '',
  }),
  computed: {
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),

    tournament() {
      return this.getTournament(this.id);
    },
    system() {
      return this.tournament?.system?.code;
    },
    rounds() {
      return this.getTournamentBracket(this.id) || [];
    },
    hasBracket() {
      if (
        (_.isArray(this.rounds) && !this.rounds.length) ||
        (this.system === 'double_elim' &&
          !this.rounds.winners.length &&
          !this.rounds.loosers.length)
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.fetchTournamentBracket(this.id).finally(() => {
      this.loadingState.bracket = false;
      this.selectBracket();
    });
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournamentBracket']),

    selectBracket() {
      switch (this.system) {
        case 'single':
          this.bracketType = 'Single';
          break;
        case 'double_elim':
          this.bracketType = 'Double';
          break;
        case 'team_ffa':
          this.bracketType = 'TeamFFA';
          break;
        case 'swiss':
          this.bracketType = 'SwissTable';
          break;
        case 'subgroups':
          this.bracketType = 'GroupStageTable';
          break;
        default:
          throw new Error('No matching bracket types found');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bracket {
  &-view {
    display: flex;
    flex-direction: column;
  }
}

.loader {
  margin: 20px auto;
}

.error {
  text-align: center;
  margin-top: 40px;
}
</style>
