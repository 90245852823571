<template>
  <div class="hub-user-avatar">
    <img
      v-if="src"
      :src="src"
      class="hub-user-avatar__image"
      alt="user avatar"
    />
    <Icon v-else name="user-centered" :size="24" />
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'HubUserAvatar',
  components: {
    Icon,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
.hub-user-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #454549;
}
.hub-user-avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
</style>
