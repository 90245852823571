<template>
  <loader v-if="loadingState.schedule" />
  <div v-else class="schedule">
    <template v-if="!error">
      <tournament-schedule-table
        v-for="(table, key) in tables"
        :id="id"
        :key="key"
        :table="table.rounds"
        :title="table.name"
      />
    </template>
    <p v-else class="error">
      {{ $t('tournaments.placeholders_schedule') }}
    </p>
  </div>
</template>

<script>
import TournamentScheduleTable from '@components/TournamentComponents/TournamentScheduleTable.vue';

export default {
  components: {
    TournamentScheduleTable,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      schedule: true,
    },
    error: false,
  }),
  computed: {
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),

    tournament() {
      return this.getTournament(this.id);
    },

    system() {
      return this.tournament.system.code;
    },

    rounds() {
      return this.getTournamentBracket(this.id) || [];
    },

    tables() {
      if (this.system === 'double_elim') {
        return [
          {
            name: this.$t('tournaments.scheduleTable_topBracket'),
            rounds: this.rounds.winners,
          },
          {
            name: this.$t('tournaments.scheduleTable_bottomBracket'),
            rounds: this.rounds.losers,
          },
        ];
      }

      if (this.system === 'subgroups') {
        return this.rounds?.detail?.map((item, index) => ({
          name: `${this.$t('tournaments.scheduleTable_group')} ${
            index + 1
          }`,
          rounds: item.rounds,
        }));
      }

      return [
        {
          rounds: this.rounds,
        },
      ];
    },
  },
  created() {
    this.fetchTournamentBracket(this.id)
      .then((data) => {
        if (data === false) {
          this.error = true;
        }
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loadingState.schedule = false;
      });
  },
  methods: {
    ...mapActions('tournaments', [
      'fetchSubgroups',
      'fetchTournamentBracket',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  &-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    color: white;
    margin: 0;
    @include min-tablet() {
      flex-shrink: 0;
    }
    @include max-tablet() {
      padding: 0 12px;
    }
  }
}

.search {
  margin: 0;
  position: relative;
  @include min-tablet() {
    width: 298px;
  }
  @include max-tablet() {
    width: 100%;
    padding: 0 12px;
    margin-top: 20px;
  }

  .icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    fill: rgba(white, 0.38);
    @include min-tablet() {
      left: 12px;
    }
    @include max-tablet() {
      left: 24;
    }
  }

  &-input {
    border-radius: 3px;
    background-color: rgba(black, 0.1);
    width: 100%;
    border: 1px solid rgba(white, 0.1);
    padding-left: 30px;
    height: 32px;
    box-sizing: border-box;
    font-size: 14px;
    color: white;
    color: rgba(white, 0.38);
  }
}

.matches {
  margin-top: 18px;

  &-title {
    box-shadow: 0 2px 12px 0 rgba(black, 0.11);
    background-color: rgba($dark-two, 0.41);
    padding: 8px 40px;
    border: 1px solid rgba(white, 0.1);
    @include max-laptop() {
      display: none;
    }
  }
}

.match {
  @include min-laptop() {
    display: flex;
    align-items: center;
    &:nth-last-of-type(even) {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.11);
      background-color: rgba($dark-two, 0.41);
    }
  }
  @include max-laptop() {
    padding: 8px 12px;
    &:nth-last-of-type(odd) {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.11);
      background-color: rgba($dark-two, 0.41);
    }
  }

  &:not(.matches-title) {
    @include min-laptop() {
      padding: 16px 40px;
    }

    .match-title {
      @include min-laptop() {
        display: none;
      }
    }
  }

  &-col {
    @include max-laptop() {
      display: flex;
      align-items: baseline;
      margin-top: 8px;
    }
  }

  &-title {
    font-size: 14px;
    line-height: 1.14;
    color: rgba(white, 0.6);
    @include max-laptop() {
      width: 55px;
      flex-shrink: 0;
    }
  }

  &-info {
    font-size: 15px;
    line-height: 1.6;
    color: white;
    width: calc(100% - 55px);
  }

  .vs {
    display: inline-flex;
    align-items: baseline;
    max-width: 100%;
    @include min-laptop() {
      padding: 0 10px;
      justify-content: center;
    }

    &-left,
    &-right {
      font-weight: bold;
      vertical-align: middle;
      text-align: left;
      @include min-laptop() {
        flex-basis: 0;
        flex-grow: 1;
      }
      @include max-laptop() {
        ::v-deep .team {
          &-icon {
            display: none;
          }

          &-name {
            margin: 0;
          }
        }
      }
    }

    &-left {
      @include min-laptop() {
        text-align: right;
      }
    }

    &-divider {
      font-size: 15px;
      color: rgba(white, 0.4);
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      flex-shrink: 0;
      @include min-laptop() {
        width: 95px;
      }
      @include max-laptop() {
        margin: 0 8px;
      }

      .win {
        color: $dark-pastel-green;
        font-weight: bold;
        font-size: 17px;
      }

      .lose {
        color: $orangey-red;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }

  &-more {
    .more {
      color: $dodger-blue;
      position: relative;
      display: inline-flex;
      align-items: center;

      &:after {
        content: ' ';
        height: 0.4em;
        width: 0.4em;
        border-style: solid;
        border-width: 1px 1px 0 0;
        margin-left: 0.2em;
        transform: rotate(45deg);
        transform-origin: 50% 50%;
      }
    }
  }
}

.error {
  margin-top: 40px;
  text-align: center;
}

.loader {
  margin: 20px auto;
}
</style>
