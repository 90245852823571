<template>
  <article class="admins">
    <div class="admins__header">
      <h2 class="admins__title">{{ title }}</h2>
      <a
        v-if="discord"
        :href="discord"
        target="__blank"
        class="admins__contact"
      >
        <Icon name="discord" group="social" :size="16" />
      </a>
    </div>
    <ul class="admins__list">
      <li
        v-for="(admin, idx) in currentAdminList"
        :key="idx"
        class="admins__item"
      >
        <div class="admins__content">
          <div class="admins__user">
            <div class="admins__col">
              <HubUserAvatar :src="admin.avatarUrl" />
            </div>
            <div class="admins__col">
              <p class="admins__user-name">{{ admin.nick }}</p>
              <p class="admins__user-role">{{ admin.role }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="isShowMoreButton" class="admins__footer">
      <Button
        tag="button"
        size="regular"
        type="tertiary"
        :loading="false"
        :text="buttonText"
        long
        @click="handleButtonClick"
      />
    </div>
  </article>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Button from '@components/v2/ui/Button.vue';
import HubUserAvatar from '@components/v2/hub/HubUserAvatar.vue';

export default {
  name: 'Admins',
  components: {
    Icon,
    Button,
    HubUserAvatar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    discord: {
      type: String,
      default: '',
    },
    admins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowAllAdmins: false,
    };
  },
  computed: {
    currentAdminList() {
      if (this.isShowAllAdmins) {
        return this.admins;
      }
      return this.admins.slice(0, 4);
    },
    isShowMoreButton() {
      return this.currentAdminList.length < this.admins.length;
    },
  },
  methods: {
    handleButtonClick() {
      this.isShowAllAdmins = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.admins {
  padding: 16px 16px 32px;
  border-radius: 12px;
  background: #212222;
  overflow: auto;
  position: relative;

  @include min-desktop() {
    padding: 24px 16px 32px;
  }
}
.admins__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.admins__title {
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
.admins__item {
  list-style: none;
  margin-bottom: 24px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}
.admins__content {
  display: flex;
  align-items: center;
}
.admins__image {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
}
.admins__user-name {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.admins__user-role {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.admins__user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.admins__contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 30px;
  display: flex;
  padding: 8px 10px;
  border-radius: 6px;
  color: #979797;
  background: #454549;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: #f5f5f7;
  }
}
.admins__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 84px;
  padding: 36px 16px 16px;
  background: linear-gradient(
    180deg,
    #212222 0%,
    rgba(33, 34, 34, 0) 0.01%,
    #212222 64.06%
  );
}
</style>
