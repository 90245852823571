<template>
  <BaseLink
    v-if="user"
    :to="{ name: 'profile', params: { hash: user.hash } }"
    :title="$t('profile.linkTooltip', { name: user.name })"
    :class="classes"
  >
    <user-avatar
      v-if="displayImage"
      class="user-icon"
      :style="iconStyle"
      :src="user.image"
    />
    <div v-if="displayName" class="user-name">
      <slot name="username">{{ user.name }}</slot>
      <div v-if="$slots.gamenick" class="gamenick">
        <slot name="gamenick" />
      </div>
      <div v-if="$slots.userrole" class="role">
        <slot name="userrole" />
      </div>
    </div>
  </BaseLink>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'BaseUserLink',
  components: {
    BaseLink,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
    imageSize: {
      type: [Number, String],
      default: 32,
    },
    displayName: {
      type: Boolean,
      default: true,
    },
    displayImage: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    ...mapGetters('users', ['getUserProfile']),

    user() {
      return this.getUserProfile(this.hash);
    },
    iconStyle() {
      return {
        width: `${this.imageSize}px`,
        height: `${this.imageSize}px`,
      };
    },
    classes() {
      return [
        'user-link',
        {
          [`user-link-${this.variant}`]: !!this.variant,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  &-link {
    display: inline-flex;
    align-items: center;
    color: white;
    overflow: hidden;

    &,
    &:hover {
      text-decoration: none;
    }
    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include max-laptop() {
        flex-direction: row;
        justify-content: flex-start;
      }
      .user-icon {
        & + .user-name {
          margin-left: 0;
          @include max-laptop() {
            margin-left: 12px;
          }
        }
      }
      .user-name {
        width: inherit;
        margin-top: 16px;
        @include max-laptop() {
          margin-top: 0;
          max-width: 100%;
          min-width: 192px;
        }
      }
    }
  }

  &-icon {
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    text-transform: uppercase;

    & + .user-name {
      margin-left: 1em;
    }
  }

  &-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .role {
      line-height: 1;
      font-size: 15px;
      color: rgba(white, 0.5);
    }
  }
}

.gamenick {
  opacity: 0.7;
  font-size: 13px;
  margin-top: 2px;

  ::v-deep .game-tag {
    margin-right: 5px;
  }

  & + .role {
    margin-top: 12px;
  }
}
</style>
