<template>
  <a :href="href" target="_blank" class="hub-organization">
    <div class="hub-organization__avatar">
      <img
        v-if="image"
        class="hub-organization__image"
        :src="image"
        alt="organization image"
      />
      <Icon v-else name="team" :size="24" :inline="false" />
      <div v-if="isMark" class="hub-organization__label">
        <Icon
          name="check-mark"
          group="color"
          :size="14"
          :inline="false"
        />
      </div>
    </div>
    <div class="hub-organization__main">
      <h3 class="hub-organization__title">{{ title }}</h3>
      <span class="hub-organization__subtitle">{{ subtitle }}</span>
    </div>
  </a>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'HubOrganization',
  components: {
    Icon,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    isMark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.hub-organization {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.hub-organization__avatar {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  color: #f5f5f7;
  background: var(--button-bg-tertiary);
}
.hub-organization__image {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
.hub-organization__main {
  display: grid;
}
.hub-organization__label {
  position: absolute;
  top: -2px;
  right: -2px;
  line-height: 1em;
}
.hub-organization__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #f5f5f7;
}
.hub-organization__subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #979797;
}
</style>
