<template>
  <th class="table-head">
    <div class="table-head__cell" :class="cellModifyClass">
      <slot />
    </div>
  </th>
</template>

<script>
export default {
  name: 'TableHead',
  props: {
    isAlignLeft: {
      type: Boolean,
      default: false,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cellModifyClass() {
      return {
        'table-head__cell--left': this.isAlignLeft,
        'table-head__cell--highlight': this.isHighlight,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-head {
  background: #212222;
  border-top: 1px solid rgba(245, 245, 247, 0.12);
  border-bottom: 1px solid rgba(245, 245, 247, 0.12);

  &:first-child {
    border-left: 1px solid rgba(245, 245, 247, 0.12);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-right: 1px solid rgba(245, 245, 247, 0.12);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.table-head__cell {
  padding: 12px 16px;
  color: #979797;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  position: relative;
  z-index: 2;
  white-space: nowrap;

  &--highlight {
    color: #65cb6d;
  }

  &:not(&--left) {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
}
</style>
