<template>
  <div class="table-column">
    <div class="column-title">{{ $t('teams.single') }}</div>
    <BaseTeamLink v-if="hash" :hash="hash" />
  </div>
</template>

<script>
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  name: 'CellTeam',
  components: {
    BaseTeamLink,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-column {
  overflow: hidden;
}

.team-link {
  vertical-align: top;
}
</style>
