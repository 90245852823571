export const infinityScroll = (HTMLElement, callback) => {
  if (HTMLElement) {
    const windowHeight = document.documentElement.clientHeight;
    const elementCoords = HTMLElement.getBoundingClientRect();
    const isBottomVisible =
      elementCoords.bottom < windowHeight && elementCoords.bottom > 0;

    if (isBottomVisible) {
      callback();
    }
  }
};
