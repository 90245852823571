<template>
  <div
    class="
      pvp-table
      adaptive
      stripped
      rating-table rating-players-table
    "
  >
    <div class="table-header">
      <div class="table-row">
        <cell-header t="places.single" />
        <cell-header t="ratings.table_team" />
        <cell-header t="rating.summary_match_count" />
        <cell-header t="rating.summary_kill" />
        <cell-header t="rating.summary_death" />
        <cell-header t="match.Убийств / смертей" />
        <cell-header t="rating.summary_hs" />
      </div>
    </div>
    <div class="table-body">
      <div
        v-for="(place, index) in places"
        :key="index"
        class="table-row"
        :class="{ active: index === activeIndex }"
      >
        <cell-simple
          t="places.single"
          :value="getPlayerPlace(index + 1)"
        />
        <cell-team :hash="place.hash" />
        <cell-simple
          t="rating.summary_match_count"
          :value="place.matchesCount"
        />
        <cell-simple
          t="rating.summary_kill"
          :value="place.killsCount"
        />
        <cell-simple
          t="rating.summary_death"
          :value="place.deathsCount"
        />
        <cell-simple
          t="match.Убийств / смертей"
          :value="place.kill2death"
        />
        <cell-simple
          t="rating.summary_hs"
          :value="place.headshotsCount"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import CellTeam from '@components/TableCells/CellTeam.vue';

export default {
  name: 'RatingPlayersTable',
  components: {
    CellHeader,
    CellSimple,
    CellTeam,
  },
  props: {
    rating: {
      type: Object,
      required: true,
    },
    activeIndex: {
      type: Number,
      default: null,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    places() {
      return this.rating.places;
    },
  },
  methods: {
    getPlayerPlace(index) {
      return (
        (this.pagination.page - 1) * this.pagination.perPage + index
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-table {
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  &.adaptive {
    .table {
      &-header {
        @include max-laptop() {
          display: none;
        }
      }

      &-row {
        @include min-laptop() {
          display: grid;
          grid-template-columns: 90px 1fr 150px 100px 100px 150px 200px;
        }
      }

      &-column {
        @include max-laptop() {
          ::v-deep .column-title {
            flex: 0 0 85px;
          }
        }
      }
    }
  }

  &.rubber {
    .table {
      &-column {
        ::v-deep .column-title {
          display: none;
        }
      }

      &-row {
        display: grid;
        grid-template-columns: 90px 1fr 150px 100px 100px 150px 200px;
      }
    }
  }
}

.table {
  &-row.active {
    &,
    ::v-deep .user-link,
    ::v-deep .team-link {
      font-weight: bold;
      color: $azure;
    }
  }

  &-column {
    &.penalty {
      .button {
        color: rgba(white, 0.5);
        margin-left: 4px;
      }
    }
  }
}
</style>
