<template>
  <div class="table-column">
    <div class="column-title">{{ $t('date.single') }}</div>
    <div class="column-content">
      {{ $dt(ts, format) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellDate',
  props: {
    ts: {
      type: Number,
      required: true,
    },
    format: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.column-content {
  font-size: 15px;
  color: rgba(white, 0.4);
}
</style>
