<template>
  <div class="confirm-icon">
    <div
      class="confirm-icon__wrapper"
      :class="{ 'confirm-icon__wrapper--checked': checked }"
    >
      <icon
        class="confirm-icon__icon"
        name="check-circle"
        :inline="false"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'ConfirmIcon',
  components: {
    Icon,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-icon__wrapper {
  color: #979797;
}
.confirm-icon__icon {
  width: 40px;
  height: 40px;
}
.confirm-icon__wrapper--checked {
  color: #65cb6d;
}
</style>
