<template>
  <Table>
    <template #thead>
      <TableRow>
        <TableData :heading="true">
          {{ $t('places.single') }}
        </TableData>
        <TableData :heading="true" text-align="left">
          {{ $t('ratings.table_player') }}
        </TableData>
        <TableData :heading="true">
          {{ $t('ratings.table_matches') }}
        </TableData>
        <TableData :heading="true">
          {{ $t('ratings.table_win') }}
        </TableData>
        <TableData :heading="true">
          {{ $t('ratings.table_lose') }}
        </TableData>
        <TableData :heading="true">
          {{ $t('ratings.single') }}
        </TableData>
      </TableRow>
    </template>
    <template v-if="rating.length" #tbody>
      <TableRow
        v-for="(item, index) in rating"
        :key="index"
        :highlight="item.hash === currentUserHash"
      >
        <TableData>{{ item.place }}</TableData>
        <TableData text-align="left">
          <BaseLink
            :to="{ name: 'team', params: { hash: item.teamHash } }"
            class="ratings-list-table__team"
          >
            <BaseUserLinkWithoutStore
              :data="{
                hash: item.hash,
                name: item.name,
                avatarUrl: item.image,
              }"
            />
          </BaseLink>
        </TableData>
        <TableData>{{ item.matches }}</TableData>
        <TableData>{{ item.win }}</TableData>
        <TableData>{{ item.loose }}</TableData>
        <TableData>{{ item.value }}</TableData>
      </TableRow>
    </template>
  </Table>
</template>

<script>
import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'HubLeaderBoardTable',
  components: {
    Table,
    TableRow,
    TableData,
    BaseUserLinkWithoutStore,
    BaseLink,
  },
  props: {
    rating: {
      type: Array,
      required: true,
    },
    currentUserHash: {
      type: String,
      default: '',
    },
  },
};
</script>
