<!-- eslint-disable vue/no-v-html -->
<template>
  <article v-if="html" class="hub-rules" v-html="html" />
  <HubContentMessage v-else :text="$t('global.noData')" />
</template>

<script>
import HubContentMessage from '@components/v2/hub/HubContentMessage.vue';

export default {
  name: 'Rules',
  components: {
    HubContentMessage,
  },
  props: {
    html: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-rules {
  padding: 16px;
  border-radius: 12px;
  background: #212222;

  @include min-desktop() {
    padding: 24px;
  }

  :deep(ul) {
    padding-left: 32px;
  }
}
.hub-rules__title {
  color: #f5f5f7;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 16px;

  @include min-desktop() {
    font-size: 18px;
    line-height: 22px;
  }
}
.hub-rules__text {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;
}
</style>
