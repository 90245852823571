<template>
  <span ref="card" :key="data.current" class="flip-clock__piece">
    <span class="flip-clock__card flip-card">
      <b class="flip-card__top">{{ data.current }}</b>
      <b class="flip-card__bottom" :data-value="data.current"></b>
      <b class="flip-card__back" :data-value="data.previous"></b>
      <b
        class="flip-card__back-bottom"
        :data-value="data.previous"
      ></b>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  updated() {
    const el = this.$refs.card;
    if (el) {
      el.classList.remove('flip');
      /* eslint-disable */
      void el.offsetWidth;
      /* eslint-enable */
      el.classList.add('flip');
    }
  },
};
</script>

<style lang="scss">
.flip-card {
  display: block;
  position: relative;
  padding-bottom: 0.72em;
  line-height: 0.95;
  font-size: 1.8em;
}

.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  display: block;
  color: black;
  background: #f5bc01;
  padding: 0.23em 0.15em 0.4em;
  border-radius: 0.15em 0.15em 0 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 1em;
  height: 0.72em;
}

.flip-card__bottom,
.flip-card__back-bottom {
  color: black;
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 1px rgba($dark, 0.5);
  background: #f5bc01;
  border-radius: 0 0 0.15em 0.15em;
  pointer-events: none;
  overflow: hidden;
  z-index: 2;
}

.flip-card__back-bottom {
  z-index: 1;
}

.flip-card__bottom::after,
.flip-card__back-bottom::after {
  display: block;
  margin-top: -0.72em;
}

.flip-card__back::before,
.flip-card__bottom::after,
.flip-card__back-bottom::after {
  content: attr(data-value);
}

.flip-card__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0%;
  pointer-events: none;
}

.flip-card__back::before {
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.flip .flip-card__back::before {
  z-index: 1;
  animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .flip-card__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
}

@keyframes flipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
    opacity: 1;
  }
  80% {
    background: darken(#f5bc01, 10%);
    opacity: 1;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%,
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
</style>
