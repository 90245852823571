<template>
  <div class="matchmaking">
    <div class="matchmaking-wrapper">
      <div v-if="startTime" class="matchmaking-timer">
        <div class="matchmaking-timer__text">
          {{ $t('matches.waitingTime') }}
        </div>
        <div class="matchmaking-timer__timer">
          <Timer :initial="startTime" format="hh:mm:ss" />
        </div>
      </div>
      <div class="matchmaking-controls">
        <template>
          <pvp-btn
            v-if="startTime"
            size="lg"
            :is-loading="loading.isLoadingStartQueue"
            :disabled="loading.isLoadingStartQueue"
            :block="true"
            @click="leaveMatchQueue"
          >
            {{ $t('global.cancelSearch') }}
          </pvp-btn>
          <pvp-btn
            v-else
            size="lg"
            :is-loading="loading.isLoadingStartQueue"
            block
            :disabled="isDisabledStartButton"
            @click="joinMatchQueue"
          >
            {{ $t('matches.findMatch') }}
          </pvp-btn>
        </template>
      </div>
      <div class="matchmaking__queue-stats">
        <template>
          <div class="matchmaking__stats">
            {{ $t('matches.queueCount') }}:
            {{ hub.matchmakingQueueSize }}
          </div>
          <div class="matchmaking__stats">
            {{ $t('matches.activeMatches') }}:
            {{ hub.totalActiveMatches }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from '@components/v2/Timer.vue';

export default {
  name: 'MatchMakingTimer',
  components: { Timer },
  props: {
    hub: {
      type: Object,
      default: () => {},
    },
    participantState: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    startTime() {
      return this.participantState.matchmakingRegisteredAtTs;
    },
    isDisabledStartButton() {
      return !this.participantState?.matchmaking?.canRegister;
    },
  },
  methods: {
    joinMatchQueue() {
      this.$emit('find:start');
    },

    leaveMatchQueue() {
      this.$emit('find:end');
    },
  },
};
</script>

<style lang="scss" scoped>
.matchmaking {
  display: flex;
  align-items: center;
  justify-content: center;
}

.matchmaking-wrapper {
  width: 300px;
  margin: 0 auto;
}

.matchmaking-controls {
  margin-bottom: 12px;
}

.matchmaking__queue-stats {
  text-align: center;
}

.matchmaking__stats {
  font-size: 12px;
}

.matchmaking-timer {
  text-align: center;
  margin-bottom: 12px;

  &__text {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  &__timer {
    font-size: 40px;
  }
}

.matchmaking-timer,
.matchmaking-controls,
.matchmaking__queue-stats {
  background: rgba(black, 0.1);
  padding: 16px 40px;
  border-radius: 4px;
}
</style>
