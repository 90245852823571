<template>
  <div v-if="diff" class="flip">
    <slot />
    <div class="flip-clock">
      <div class="flip-clock-box">
        <FlipCard
          v-for="(day, key) in time.days"
          :key="key"
          :data="day"
        />
        <span class="flip-clock-text">
          {{ $t('date.days_few') }}
        </span>
      </div>
      <div class="flip-clock-divider">:</div>
      <div class="flip-clock-box">
        <FlipCard
          v-for="(hour, key) in time.hours"
          :key="key"
          :data="hour"
        />
        <span class="flip-clock-text">
          {{ $t('date.hours_few') }}
        </span>
      </div>
      <div class="flip-clock-divider">:</div>
      <div class="flip-clock-box">
        <FlipCard
          v-for="(minute, key) in time.minutes"
          :key="key"
          :data="minute"
        />
        <span class="flip-clock-text">
          {{ $t('date.minutes_few') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FlipCard from './FlipCard.vue';

export default {
  name: 'FlipCountdown',
  components: { FlipCard },
  props: {
    deadline: {
      type: Number,
      required: true,
    },
    stop: {
      type: Boolean,
      required: false,
    },
  },
  component: {
    FlipCard,
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      interval: null,
      diff: 0,
      show: false,
    };
  },
  computed: {
    time() {
      const formatWithZero = (time) =>
        `${time > 9 ? time : `0${time}`}`;

      const getFormatedTime = (ms) => {
        const seconds = ms % 60;
        const minutes = ((ms - seconds) / 60) % 60;
        const hours = (((ms - seconds) / 60 - minutes) / 60) % 24;
        const days = Math.trunc(ms / (60 * 60 * 24));

        return {
          seconds: formatWithZero(seconds),
          minutes: formatWithZero(minutes),
          hours: formatWithZero(hours),
          days: formatWithZero(days),
        };
      };

      const current = getFormatedTime(this.diff);
      const previous = getFormatedTime(this.diff + 1);

      return Object.keys(current).reduce(
        (time, variant) => ({
          ...time,
          [variant]: [].map.call(
            current[variant],
            (current, index) => ({
              current,
              previous: previous[variant][index],
            }),
          ),
        }),
        {},
      );
    },
  },
  watch: {
    now() {
      this.diff = this.deadline - this.now;
      if (this.diff <= 0 || this.stop) {
        this.diff = 0;
        clearInterval(this.interval);
      }
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  mounted() {
    if (this.diff !== 0) {
      this.show = true;
    }
  },
  beforeDestroy() {
    if (window.cancelAnimationFrame) {
      cancelAnimationFrame(this.frame);
    }
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
.flip-clock {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  justify-content: center;

  &__piece {
    display: inline-block;
    perspective: 100px;
    position: relative;
    margin: 0 1px;
  }

  &__slot {
    line-height: 1.5;
    display: block;
  }

  &-divider {
    font-weight: bold;
    margin: 0.5em 0.2em 0;
    font-size: 1em;
  }

  &-text {
    display: block;
    text-align: center;
  }
}
</style>
