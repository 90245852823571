<template>
  <div class="players-checkin">
    <h2 class="players-checkin__title">Подтвердите участие</h2>
    <div class="players-checkin__timeleft">
      <Timer :initial="time" format="mm:ss" countdown />
    </div>
    <div class="players-checkin__wrapper">
      <div
        v-if="action === 'checkin'"
        class="players-checkin__button"
      >
        <Button
          size="big"
          type="primary"
          :text="$t('global.confirm')"
          @click="handleButtonClick"
        />
      </div>
      <div class="players-checkin__button">
        <Button
          size="big"
          type="secondary"
          :text="$t('global.cancel')"
          @click="handleButtonClickCancel"
        />
      </div>
    </div>
    <div class="players-checkin__rules">
      <InfoBox
        v-for="(rule, idx) in rules"
        :key="idx"
        class="players-checkin__rules-item"
        variant="warning"
      >
        {{ rule.phrase }}
      </InfoBox>
    </div>
    <div class="players-checkin__main">
      <ParticipationConfirm :participants="participants" />
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import ParticipationConfirm from '@components/v2/ParticipationConfirm.vue';
import Timer from '@components/v2/Timer.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'PlayersCheckin',
  components: {
    Button,
    Timer,
    ParticipationConfirm,
    InfoBox,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    participants: {
      type: Array,
      default: () => [],
    },
    time: {
      type: Number,
      default: 0,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('confirm');
    },
    handleButtonClickCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.players-checkin {
  text-align: center;
}

.players-checkin__title {
  margin-bottom: 12px;
}
.players-checkin__timeleft {
  font-size: 36px;
  line-height: 1em;
  font-weight: 600;
}

.players-checkin__timeleft,
.players-checkin__button {
  margin-bottom: 16px;
}

.players-checkin__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.players-checkin__rules {
  margin-top: 12px;
  margin-bottom: 12px;
}

.players-checkin__rules-item {
  margin-bottom: 4px;
}
</style>
