<template>
  <p v-if="text" class="hub-content-message">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'HubContentMessage',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
.hub-content-message {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  background: #2d2d30;
  padding: 20px 12px;
  border-radius: 6px;
}
</style>
