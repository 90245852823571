<template>
  <ul class="tab-list">
    <li v-for="(tab, idx) in tabs" :key="idx" class="tab-list__item">
      <button
        class="tab-list__button"
        :class="{ 'tab-list__button--active': activeTabIdx === idx }"
        @click="handleTabClick(idx)"
      >
        {{ tab[tabKey] }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TabListButtons',
  props: {
    value: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    tabKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    activeTabIdx() {
      return this.tabs.findIndex(
        (tab) => tab[this.tabKey] === this.value[this.tabKey],
      );
    },
  },
  methods: {
    handleTabClick(index) {
      this.$emit('input', this.tabs[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-list {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 12px 16px;
  list-style-type: none;
  background: #1d1d1e;
  border-radius: 12px;
  overflow: auto hidden;
}

.tab-list__button {
  padding: 10px 12px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 36px;
  border: none;
  white-space: nowrap;

  &:not(.tab-list__button--active) {
    color: #d8d8d8;
    background: none;
    &:hover,
    &:focus {
      color: #f5f5f7;
    }
  }
}

.tab-list__button--active {
  color: #000000;
  background: #e2e3e7;
}
</style>
