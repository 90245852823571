<template>
  <div v-if="tournamentsIds.length" class="tournaments-carousel">
    <headline>
      <h2 slot="title">
        <slot name="title">{{ $t('tournaments.recommended') }}</slot>
      </h2>
    </headline>

    <hooper
      class="tournaments-slider"
      :class="{ 'no-controls': controlsDisabled }"
      :settings="settings"
      @updated="afterUpdate"
    >
      <slide v-for="(id, index) in tournamentsIds" :key="index">
        <div class="tournaments-slide">
          <tournament-card :id="id" />
        </div>
      </slide>
      <navigation slot="hooper-addons" />
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation } from 'hooper';
import TournamentCard from '@components/TournamentComponents/TournamentCard.vue';

export default {
  name: 'TournamentsCarousel',
  components: {
    TournamentCard,
    Hooper,
    Slide,
    Navigation,
  },
  props: {
    tournamentsIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    controlsDisabled: false,
    settings: {
      itemsToShow: 1.1,
      itemsToSlide: 1,
      centerMode: false,
      wheelControl: false,
      initialSlide: 1,
      breakpoints: {
        744: {
          itemsToShow: 2,
          itemsToSlide: 2,
          initialSlide: 1,
        },
        1019: {
          itemsToShow: 3,
          itemsToSlide: 3,
          initialSlide: 1,
        },
      },
    },
  }),
  methods: {
    afterUpdate({ containerWidth, slideWidth }) {
      this.controlsDisabled =
        containerWidth >= this.tournamentsIds.length * slideWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.tournaments-slider {
  margin-top: 16px;
  outline: 0 none;
  height: auto;
}

.no-controls ::v-deep .hooper-navigation {
  display: none;
}

::v-deep .hooper {
  &-slide {
    padding-right: 10px;
  }

  &-navigation {
    @include min-tablet() {
      position: absolute;
      right: 0;
      top: -50px;
      display: flex;
      width: 80px;
      height: 40px;
    }
    @include max-tablet() {
      display: none;
    }
  }

  &-prev,
  &-next {
    height: 40px;
    width: 40px;
    opacity: 0.8;
    font-size: 26px;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 1;
    }

    .icon {
      left: 0;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      top: 0;
      fill: rgba(white, 0.5);

      path:last-of-type {
        stroke: rgba(white, 0.5);
        stroke-width: 2px;
      }
    }
  }
}
</style>
