<template>
  <div v-if="tournament" class="info">
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <!-- eslint-disable vue/no-v-html -->
    <box v-if="showVideo" class="box-border tournament__video">
      <div class="wrapper">
        <div class="tournament-video">
          <div v-show="isLive" class="stream">LIVE</div>
          <div class="video-card" v-html="tournament.video.html" />
        </div>
      </div>
    </box>

    <box class="box-border shortdescription">
      <div class="wrapper">
        <BaseSocialLinks
          :share-title="shareTitle"
          :share-img="tornamentImg"
          has-copy-link
        />
        <wysiwyg-viewer
          v-if="tournament.announcementText"
          class="shortdescription-text"
          v-html="tournament.announcementText"
        />
        <template v-if="isHub">
          <div class="hub-reasons">
            <InfoBox
              v-for="(reason, idx) in matchMakingReasons"
              :key="idx"
              class="hub-reasons__reason"
              variant="warning"
            >
              <template v-if="reason.type === 'vk'">
                {{ $t('info.need_link_vkid_for_user') }}
                <a
                  target="_blank"
                  href="https://account.vkplay.ru/profile/security"
                >
                  {{ $t('info.profile_vkplay') }}
                </a>
              </template>
              <template v-else>
                {{ reason.phrase }}
                <a
                  v-if="reason.type === 'participating'"
                  :href="`/tournament/${reason.params.hubId}`"
                >
                  Перейти
                </a>
              </template>
            </InfoBox>
          </div>
          <div v-if="isHubLobbyState" class="hub-redirect-panel">
            <InfoBox
              variant="success"
              class="hub-redirect-panel__info"
            >
              {{ $t('tournaments.already_participant_match') }}
            </InfoBox>
            <pvp-btn
              :to="`/hub/${hub.id}/match/${participantState.match.id}`"
            >
              Перейти
            </pvp-btn>
          </div>
          <template v-else-if="hasMailAccount">
            <div v-if="!isLoggedIn" class="small-centered-container">
              <pvp-btn
                variant="primary"
                @click="showAdditionalRegistration"
              >
                {{ $t('account.createProfile') }}
              </pvp-btn>
            </div>
            <div
              v-else-if="isNotAccountLinked"
              class="small-centered-container"
            >
              <game-account-linker
                :id="game.id"
                @linked="handleAccountLinkSuccess"
              />
            </div>
            <MatchMakingTimer
              v-else-if="
                hub.isMatchmakingEnabled && !isParticipantAnotherHub
              "
              :hub="hub"
              :participant-state="participantState"
              :loading="loading"
              @find:start="handleStartMatchmaking"
              @find:end="handleEndMatchmaking"
            />
          </template>
          <div v-else class="small-centered-container">
            <pvp-btn variant="primary" @click="login">
              {{ $t('account.authorize') }}
            </pvp-btn>
          </div>
        </template>
        <participation-info v-else :id="id" />

        <box v-if="tournament.hasAntiCheat" class="infobox anticheat">
          <div class="infobox-icon">
            <div class="iconbox">
              <icon name="anticheat" />
            </div>
            <span class="iconbox-text">{{
              $t('global.anticheat')
            }}</span>
          </div>
          <p class="infobox-text">
            {{ $t('tournaments.anticheat_infobox_text') }}
            <a
              target="_blank"
              href="https://support.vkplay.ru/pvp/anticheat"
            >
              {{ $t('tournaments.anticheat_infobox_bylink') }}</a
            >.
          </p>
        </box>

        <rules-infobox
          v-if="restrictions && !isHub"
          :restrictions="restrictions"
          :game-id="tournament.idGame"
          :start="tournament.tsStartRun"
          :end-reg="tournament.tsEndReg"
        />

        <RulesHub v-else :rules="hubRules" />

        <div class="infobox trusted">
          <icon
            :class="{ enabled: tournament.isTrusted }"
            :name="tournament.isTrusted ? 'trusted' : 'untrusted'"
          />
          <div class="infobox-text">
            {{ infoboxTrustedText }}
          </div>
        </div>
      </div>
    </box>

    <box
      v-if="tournament.description"
      class="box-border description detailed"
    >
      <div class="wrapper">
        <h4 class="description-title">
          {{ $t('tournaments.description_detailed') }}
        </h4>
        <wysiwyg-viewer
          v-html="
            tournament.description
              .replaceAll(';amp', '')
              .replace('&amp;', '&')
          "
        />
      </div>
    </box>

    <div v-if="socials.length" class="sectionbox soc">
      <h2 class="sectionbox-title">
        {{ $t('tournaments.socials') }}
      </h2>
      <box class="box-border">
        <div class="wrapper">
          <a
            v-for="(item, index) in socials"
            :key="index"
            class="share-link"
            :href="item.link"
          >
            <icon :name="socialsIcons[item.code]" />
            <span class="share-text"> {{ item.name }} </span>
          </a>
        </div>
      </box>
    </div>

    <TournamentsCarousel
      v-if="sliderIds.length"
      :tournaments-ids="sliderIds"
      class="slider"
    >
      <span slot="title" class="slider-title">
        {{ $t('tournaments.few') }} {{ game.name }}
        <BaseLink
          :to="{ name: 'tournaments', query: { games: game.id } }"
          icon-right="arrow-right"
          class="slider-link"
        >
          {{ $t('tournaments.all') }}
        </BaseLink>
      </span>
    </TournamentsCarousel>
  </div>
</template>

<script>
import { getGameImg } from '@src/gamesImages';
import { auth } from '@src/plugins/Auth';

import GameAccountLinker from '@components/ProfileComponents/GameAccount/GameAccountLinker.vue';
import TournamentsCarousel from '@components/TournamentComponents/TournamensCarousel.vue';
import ParticipationInfo from '@components/TournamentComponents/ParticipationInfo.vue';
import RulesInfobox from '@components/TournamentComponents/RulesInfobox.vue';
import RulesHub from '@components/TournamentComponents/RulesHub.vue';
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';
import MatchMakingTimer from '@views/MatchMaking/MatchMakingTimer.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  components: {
    GameAccountLinker,
    WysiwygViewer,
    ParticipationInfo,
    TournamentsCarousel,
    RulesInfobox,
    RulesHub,
    MatchMakingTimer,
    Icon,
    BaseSocialLinks,
    BaseLink,
    InfoBox,
  },
  props: {
    id: {
      type: [Number, String],
      require: true,
      default: null,
    },
    gameId: {
      type: Number,
      required: true,
    },
    isHub: {
      type: Boolean,
      default: false,
    },
    hub: {
      type: Object,
      default: () => {},
    },
    participantState: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    socials: [],
    sliderIds: [],
    socialsIcons: {
      vk: 'vk',
      fb: 'fb',
      ok: 'ok',
      tw: 'tw',
      ds: 'discord',
      yt: 'youtube',
    },
  }),
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('profile', [
      'isWithoutGameAccountLinkModal',
      'hasMailAccount',
      'isLoggedIn',
    ]),
    ...mapGetters('application', ['getGame', 'getGameByCode']),

    isHubLobbyState() {
      return this.participantState?.status === 'match';
    },

    matchMakingReasons() {
      return this.participantState?.matchmaking?.reasons;
    },

    isParticipantAnotherHub() {
      return this.participantState?.matchmaking?.reasons.some(
        (reason) => reason.type === 'participating',
      );
    },

    isNotAccountLinked() {
      return this.participantState?.matchmaking?.reasons.some(
        (reason) => reason.type === 'gameProfile',
      );
    },

    tournament() {
      return this.getTournament(this.id);
    },

    restrictions() {
      return this.tournament.restrictions;
    },

    hubRules() {
      return this.hub?.rules;
    },

    game() {
      return (
        this.getGame(this.tournament.idGame) ||
        this.getGameByCode('oth')
      );
    },

    tornamentImg() {
      return getGameImg(this.game.code, 'small');
    },

    shareTitle() {
      return this.$t('tournaments.share', {
        name: this.tournament.name,
        host: window.location.host.toLocaleUpperCase(),
      });
    },

    showVideo() {
      const now = Math.trunc(Date.now() / 1000);
      return (
        !!this.tournament.video &&
        this.tournament.video.publishTimeFull.ts <= now
      );
    },
    isLive() {
      const now = Math.trunc(Date.now() / 1000);
      return (
        this.tournament.video.stream &&
        this.tournament.video.streamEndTimeFull.ts > now
      );
    },

    infoboxTrustedText() {
      return this.$t(
        `tournaments.tooltips_trusted_${
          this.tournament.isTrusted ? 'enabled' : 'disabled'
        }-full`,
        {
          host: window.location.host.toLocaleUpperCase(),
        },
      );
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournaments']),

    fetchData() {
      this.fetchTournaments({
        gameId: [this.tournament.idGame],
      }).then((data) => {
        this.sliderIds =
          data?.tournaments?.filter((id) => id !== this.id) || [];
      });

      api
        .get(`/tournament/${this.id}/socialdiscuss`)
        .then(({ links = [] }) => {
          this.socials = links;
        });
    },

    login() {
      const { backUrl } = this.$route.query;
      auth.login({ backUrl });
    },
    showAdditionalRegistration() {
      this.$store.commit(
        'profile/SET_IS_WITHOUT_GAME_ACCOUNT_LINK_MODAL',
        true,
      );
      this.$store.commit(
        'profile/SET_SHOW_ADDITIONAL_REGISTRATION',
        true,
      );
    },

    handleAccountLinkSuccess() {
      this.$emit('account:linked');
    },
    async handleStartMatchmaking() {
      await api.post(`hub/${this.id}/register`);
    },
    async handleEndMatchmaking() {
      api.delete(`hub/${this.id}/register`);
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  padding: 20px 0;
}

.title {
  font-weight: bold;
  font-size: 28px;
}

.wrapper {
  margin: 0 auto;
  max-width: 800px + 24;
  padding-left: 12px;
  padding-right: 12px;
}

.button {
  &-reg {
    display: block;
    min-width: 160px;
    margin: 20px auto 0;
  }
}

.shortdescription {
  padding: 30px 0;

  &-text {
    margin-top: 20px;
  }

  .button-reg {
    @include max-tablet() {
      width: 100%;
    }
  }

  .vs {
    @include min-tablet() {
      padding: 20px 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    @include max-tablet() {
      padding: 16px;
    }

    &-text {
      font-size: 17px;
      font-weight: bold;
      color: rgba(white, 0.3);
      margin: 0 12px;
    }

    &-countdown {
      font-size: 14px;
      color: rgba(white, 0.4);
      @include min-tablet() {
        margin-left: 20px;
      }
      @include max-tablet() {
        margin-top: 8px;
      }
    }

    &-stage {
      font-size: 18px;
      font-weight: bold;
      color: white;
      order: 1;
      @include min-tablet() {
        margin-left: 14px;
      }
      @include max-tablet() {
        margin-top: 12px;
      }
    }
  }
}

.infobox {
  margin-top: 20px;
  border: 1px solid $azure;
  border-radius: 6px;
  display: flex;
  @include min-tablet() {
    padding: 16px 20px;
  }
  @include max-tablet() {
    padding: 14px;
  }

  &-icon {
    line-height: 1;
    flex-shrink: 0;
    text-align: center;
  }

  &-text {
    margin: 0 0 0 20px;
    line-height: 1.47;
    color: rgb(176, 176, 176);
    @include min-tablet() {
      font-size: 17px;
    }
    @include max-tablet() {
      font-size: 15px;
    }

    &.list {
      padding-left: 20px;
    }
  }

  &.anticheat {
    .iconbox {
      height: 36px;
      width: 36px;
      background: black;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      border: 1px solid $azure;
      border-radius: 2px;

      &-text {
        font-size: 12px;
      }

      .icon {
        color: $azure;
        width: 20px;
        height: 26px;
      }
    }
  }

  &.rules {
    color: rgba(255, 255, 255, 0.5);
    font-size: 26px;
  }

  &.trusted {
    .icon {
      color: $burnt-red;
      width: 24px;
      height: 26px;
      flex-shrink: 0;

      &.enabled {
        color: $dark-pastel-green;
      }
    }
  }
}

.tournament__video {
  margin-bottom: 20px;
  padding: 20px 0;

  @include min-tablet() {
    padding: 30px 0;
  }
}

.description {
  margin-top: 20px;
  padding: 20px 0;

  &-title {
    line-height: 1;
    font-weight: bold;
    border-bottom: 1px solid rgba(white, 0.1);
    margin-bottom: 16px;
    @include min-laptop() {
      font-size: 20px;
      padding-bottom: 16px;
    }
    @include max-laptop() {
      font-size: 18px;
      padding-bottom: 14px;
    }
  }
}

.sectionbox {
  margin-top: 50px;

  &-title {
    @include min-laptop() {
      font-size: 24px;
    }
    @include max-laptop() {
      font-size: 18px;
    }
    @include max-tablet() {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .box {
    margin-top: 20px;
  }

  .share-link {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    font-size: 15px;
    color: rgba(white, 0.5);
    text-decoration: none;

    &:hover {
      color: white;

      .icon {
        fill: white;
      }
    }

    .icon {
      display: block;
      margin: 0 auto 8px;
      width: 40px;
      height: 40px;
      fill: rgba(white, 0.5);
    }

    .share-text {
      @include max-tablet() {
        display: none;
      }
    }
  }
}

.chat {
  .wrapper {
    @include min-tablet() {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-text {
    margin: 0;
    font-size: 18px;
  }

  &-count {
    margin: 0;
    font-size: 15px;
    color: rgba(white, 0.5);
    @include max-tablet() {
      margin-top: 8px;
    }
  }

  &-button {
    @include max-tablet() {
      margin-top: 24px;
      width: 100%;
    }
  }
}

.soc {
  .wrapper {
    display: flex;
    justify-content: center;
  }
}

.slider {
  margin-top: 50px;
  @include max-laptop() {
    ::v-deep .hooper-navigation {
      display: none;
    }
  }
  @include max-tablet() {
    padding: 0 12px;
  }

  &-title {
    @include min-laptop() {
      font-size: 24px;
    }
    @include max-laptop() {
      font-size: 18px;
    }
  }

  &-link {
    margin-left: 20px;
    font-size: 17px;
    @include max-laptop() {
      display: none;
    }
  }
}

.participation-info {
  margin-top: 20px;
}

.tournament-video {
  margin: 20px 0;
  position: relative;
  aspect-ratio: 16 / 9;

  .stream {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0 4px 0 14px;
    font-size: 13px;
    background-color: rgba(39, 37, 50, 0.8);

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      left: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $orangey-red-three;
    }
  }

  .video-card {
    width: 100%;
    height: 100%;
  }
}

.hub-redirect-panel {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 12px;
}
.hub-redirect-panel__info {
  margin-bottom: 12px;
}

.hub-reasons {
  margin-top: 12px;
}

.hub-reasons__reason {
  margin-bottom: 8px;
}

.hub-reasons {
  margin-top: 12px;
}

.hub-reasons__reason {
  margin-bottom: 8px;
}

.small-centered-container {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}
</style>
