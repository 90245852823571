<template>
  <article class="match-card">
    <div class="match-card__header">
      <a :href="link" target="_blank" class="match-card__link">
        <Icon name="chevron" inline />
      </a>
    </div>
    <div class="match-card__result">
      <div class="match-card__team match-card__team--home">
        <div class="match-card__team-name">
          {{ homeTeam.name }}
        </div>
        <div class="match-card__team-avatar">
          <img
            v-if="homeTeam.avatarLink"
            class="match-card__team-image"
            :src="homeTeam.avatarLink"
            alt="team avatar"
          />
          <Icon v-else name="team" :size="24" :inline="false" />
        </div>
      </div>
      <div class="match-card__total">
        <div
          v-if="status"
          class="match-card__label"
          :class="labelClass"
        >
          {{ statusText }}
        </div>
        <div class="match-card__scores">
          <span
            class="match-card__score"
            :class="getScoreModifyClass(score.home, score.visitor)"
          >
            {{ score.home }}
          </span>
          <span class="match-card__separator">
            <Icon name="colon" inline />
          </span>
          <span
            class="match-card__score"
            :class="getScoreModifyClass(score.visitor, score.home)"
          >
            {{ score.visitor }}
          </span>
        </div>
      </div>
      <div class="match-card__team match-card__team--visitor">
        <div class="match-card__team-name">
          {{ visitorTeam.name }}
        </div>
        <div class="match-card__team-avatar">
          <img
            v-if="visitorTeam.avatarLink"
            class="match-card__team-image"
            :src="visitorTeam.avatarLink"
            alt="team avatar"
          />
          <Icon v-else name="team" :size="24" :inline="false" />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'MatchCard',
  components: { Icon },
  props: {
    link: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    statusText: {
      type: String,
      default: '',
    },
    score: {
      type: Object,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    visitorTeam: {
      type: Object,
      required: true,
    },
  },
  computed: {
    labelClass() {
      return this.status ? `match-card__label--${this.status}` : '';
    },
  },
  methods: {
    getScoreModifyClass(ownScore, opponentScore) {
      if (ownScore === opponentScore) return '';
      return ownScore > opponentScore
        ? 'match-card__score--win'
        : 'match-card__score--lose';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-card {
  width: 100%;
  color: #f5f5f7;
  background: #212222;
  padding: 8px;
  border-radius: 6px;
  position: relative;

  @include min-tablet() {
    padding: 12px 80px;
    border-radius: 12px;
  }
}
.match-card__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;

  @include min-tablet() {
    display: block;
    margin-bottom: 0;
  }
}
.match-card__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 2px;
  cursor: pointer;
  color: inherit;
  background: #212222;

  @include min-tablet() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80px;
    font-size: 22px;
    border-radius: 0 12px 12px 0;
  }

  @include min-desktop() {
    &:hover {
      background: rgba(245, 245, 247, 0.12);
      color: inherit;
    }
  }
}
.match-card__label {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 2px 4px;
  border-radius: 6px;
  position: absolute;
  top: 8px;
  left: 8px;

  @include min-tablet() {
    position: static;
    display: inline-block;
    padding: 4px 6px;
    margin-bottom: 12px;
  }
  @include min-desktop() {
    font-size: 14px;
    line-height: 18px;
  }

  &--match {
    background: #65cb6d;
  }
  &--finished {
    background: #979797;
  }
  &--canceled {
    background: #c64040;
  }
}
.match-card__result {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  @include min-tablet() {
    align-items: flex-end;
  }
}
.match-card__team {
  flex-shrink: 0;
  width: 33%;

  @include min-tablet() {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &--home {
    text-align: center;

    @include min-tablet() {
      text-align: right;
    }
  }
  &--visitor {
    text-align: center;

    @include min-tablet() {
      text-align-last: left;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}
.match-card__team-name {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 6px;

  @include min-tablet() {
    width: calc(100% - 48px);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-bottom: 0;
  }
  @include min-desktop() {
    font-size: 18px;
    line-height: 22px;
  }
}
.match-card__team-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0 auto;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #454549;

  @include min-tablet() {
    margin: unset;
    width: 48px;
    height: 48px;
  }
}
.match-card__team-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
.match-card__total {
  width: 80px;
  flex-shrink: 0;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;

  @include min-tablet() {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 160px;
    font-size: 26px;
    line-height: 30px;
  }
}
.match-card__scores {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  @include min-tablet() {
    gap: 14px;
  }
}
.match-card__score {
  &--win {
    color: #65cb6d;
  }
  &--lose {
    color: #c64040;
  }
}
.match-card__separator {
  font-size: 0.5em;
  line-height: 1em;

  :deep(svg) {
    display: block;
  }
}
</style>
