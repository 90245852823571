<template>
  <div v-if="tournament" class="participation-info">
    <premium-banner
      v-if="showPremiumBanner && !loadingState.registerData"
      :need-premium="needPremium"
      class="premium"
    />

    <div class="participation-controls">
      <!-- TODO интегрировать сюда новый комонент Регистрации после рефакторинга  -->
      <pvp-btn
        v-if="canRegister"
        :is-loading="
          isRegisteringToTournament(id) || loadingState.registerData
        "
        :disabled="isStartRegistration"
        @click="startRegistration(id)"
        >{{ $t('global.register') }}
      </pvp-btn>
      <a
        v-if="isPayablePrizeParticipant"
        class="prize-link"
        :href="prizePayoutInfoUrl"
        target="_blank"
      >
        {{ $t('global.payablePrizeParticipantButton') }}
      </a>
    </div>

    <template v-if="participationStatus">
      <template v-if="isRegOpen && participationStatus.isRegistered">
        <InfoBox>
          <i18n path="participation.teamRequest">
            <BaseTeamLink
              slot="team"
              :key="team.hash"
              :hash="team.hash"
              :display-image="false"
            />
          </i18n>
        </InfoBox>
        <div
          v-if="participationStatus.canDeny"
          class="participation-controls"
        >
          <!-- TODO интегрировать сюда новый комонент фичи cancelRegistration после рефакторинга  -->
          <pvp-btn
            :disabled="loadingState.cancel"
            :is-loading="loadingState.cancel"
            variant="secondary"
            @click="cancelRegistration"
            >{{ $t('participation.cancelRequest') }}
          </pvp-btn>
        </div>
      </template>

      <template v-if="confirmationAvailable">
        <InfoBox variant="success">
          <i18n path="participation.accepted">
            <BaseTeamLink
              slot="link"
              :key="team.hash"
              :hash="team.hash"
              :display-image="false"
            />
          </i18n>
          {{
            $t(
              isTeamCaptain
                ? 'participation.confirmMy'
                : 'participation.confirmCaptain',
            )
          }}
        </InfoBox>

        <div
          v-if="participationStatus.canConfirm"
          class="participation-controls"
        >
          <pvp-btn
            :disabled="loadingState.confirm"
            :is-loading="loadingState.confirm"
            variant="warning"
            @click="confirmRegistration"
            >{{ $t('participation.confirm') }}
          </pvp-btn>
        </div>
      </template>

      <InfoBox v-if="missedConfirmation" variant="error">
        <i18n path="participation.teamNotAllowed">
          <BaseTeamLink
            slot="team"
            :hash="team.hash"
            :display-image="false"
          />
        </i18n>
      </InfoBox>

      <InfoBox v-if="teamNotFormedError" variant="error">
        <i18n path="participation.teamNotFormed">
          <BaseTeamLink
            slot="team"
            :hash="team.hash"
            :display-image="false"
          />
        </i18n>
      </InfoBox>

      <template v-if="participationStatus.status === 'selected'">
        <template v-if="tournament.status.code === 'executing'">
          <template v-if="closestMatch">
            <div class="closest-match-title">
              {{ $t('participation.nextMatch') }}
            </div>
            <template v-if="isTeamFFA">
              <div v-if="!timerIsOut" class="closest-match">
                <BaseTeamLink class="team" :hash="team.hash" />
                <BaseTeamLink
                  class="enemy-team"
                  :hash="enemyTeam.hash"
                />
                <i18n
                  path="matches.matchStart"
                  tag="div"
                  class="time-left"
                >
                  <time-left
                    slot="time"
                    :to="closestMatch.dateTs"
                    is-server-time
                    @timeout="hideClosestMatchBlock"
                  />
                </i18n>
              </div>
            </template>
            <div v-else class="closest-match">
              <BaseTeamLink class="team" :hash="team.hash" />
              <div class="versus">VS</div>
              <BaseTeamLink
                class="enemy-team"
                :hash="enemyTeam.hash"
              />
              <i18n
                path="matches.matchStart"
                tag="div"
                class="time-left"
              >
                <time-left
                  slot="time"
                  :to="closestMatch.dateTs"
                  is-server-time
                />
              </i18n>
            </div>
            <div class="participation-controls">
              <pvp-btn
                :to="{
                  name: 'match',
                  params: { id: closestMatch.id },
                }"
              >
                {{ $t('matches.toMatch') }}
              </pvp-btn>
            </div>
          </template>
          <template v-else-if="completedMatch">
            <InfoBox variant="success">
              <i18n path="participation.finished">
                <BaseTeamLink
                  slot="team"
                  :hash="team.hash"
                  :display-image="false"
                />
              </i18n>
              <template v-if="completedMatch.emptyResults">
                {{ $t('participation.setResultHint') }}
              </template>
            </InfoBox>
            <div class="participation-controls">
              <pvp-btn
                :to="{
                  name: 'match',
                  params: { id: completedMatch.id },
                }"
              >
                {{ $t('matches.toMatch') }}
              </pvp-btn>
            </div>
          </template>

          <div v-else-if="matchMakingVisible" class="matchmaking">
            <div
              v-if="
                ratingSystem.waitMatch &&
                !mmMatchId &&
                mmQueueStartTime
              "
              class="block queue-timer"
            >
              <div class="timer-text">
                {{ $t('matches.waitingTime') }}
              </div>
              <time-left
                reverse
                :to="mmQueueStartTime"
                format="hh:mm:ss"
                is-server-time
              />
            </div>
            <div class="block queue-controls">
              <template v-if="!mmMatchId">
                <pvp-btn
                  v-if="ratingSystem.canFindMatch"
                  size="lg"
                  :is-loading="loadingState.queue"
                  :disabled="loadingState.queue"
                  :block="true"
                  @click="joinMatchQueue"
                  >{{ $t('matches.findMatch') }}
                </pvp-btn>
                <pvp-btn
                  v-if="ratingSystem.waitMatch"
                  size="lg"
                  :is-loading="loadingState.queue"
                  :disabled="loadingState.queue"
                  :block="true"
                  @click="leaveMatchQueue"
                  >{{ $t('global.cancelSearch') }}
                </pvp-btn>
              </template>
              <template v-else>
                <div class="queue-controls-text">
                  {{ $t('matches.matchFound') }}
                </div>
                <pvp-btn
                  :block="true"
                  :to="{ name: 'match', params: { id: mmMatchId } }"
                  >{{ $t('matches.toMatch') }}
                </pvp-btn>
              </template>
            </div>
            <div class="block queue-stats">
              <template v-if="mmStatsText">
                <div
                  v-for="(text, index) in mmStatsText"
                  :key="index"
                  class="stats-line"
                >
                  {{ $tp(text) }}
                </div>
              </template>
              <template v-else>
                <div class="stats-line">
                  {{ $t('matches.averageTime') }}:
                  {{ ratingSystem.avgTime }}
                </div>
                <div class="stats-line">
                  {{ $t('matches.queueCount') }}:
                  {{ ratingSystem.stat.queueSize }}
                </div>
                <div class="stats-line">
                  {{ $t('matches.activeMatches') }}:
                  {{ ratingSystem.stat.activeMatchesCount }}
                </div>
              </template>
            </div>
          </div>
          <InfoBox v-else variant="success">
            <i18n path="participation.teamParticipating">
              <BaseTeamLink
                slot="team"
                :hash="team.hash"
                :display-image="false"
              />
            </i18n>
          </InfoBox>
        </template>
        <InfoBox v-else variant="success">
          <i18n path="participation.confirmParticipation">
            <BaseTeamLink
              slot="team"
              :hash="team.hash"
              :display-image="false"
            />
          </i18n>
        </InfoBox>
      </template>

      <InfoBox v-if="participationErrorText" variant="error">
        <i18n :path="participationErrorText">
          <BaseTeamLink
            slot="team"
            :hash="team.hash"
            :display-image="false"
          />
        </i18n>
      </InfoBox>
    </template>
  </div>
</template>

<script>
import PremiumBanner from '@components/TournamentComponents/Premium/PremiumBanner.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import { formatDuration } from '@utils/dates';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'ParticipationInfo',
  components: {
    PremiumBanner,
    BaseTeamLink,
    InfoBox,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      cancel: false,
      confirm: false,

      queue: false,
      registerData: false,
    },

    registerInfo: null,
    registerFlags: {},
    needPremium: false,

    // Matchmaking
    mmStatsText: null,
    mmMatchId: null,
    mmQueueJoinTime: null,
    timerIsOut: false,

    team: {},
  }),
  computed: {
    ...mapGetters('tournamentRegistration', [
      'isRegisteringToTournament',
      'isStartRegistration',
    ]),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('profile', ['isLoggedIn']),

    isIncludesPrize() {
      return Boolean(this.registerFlags.prize);
    },

    isPayablePrizeParticipant() {
      return this.isIncludesPrize &&
        this.registerFlags.prize.status !== 'paid'
        ? this.registerFlags.prize.isPayablePrizeParticipant
        : false;
    },

    prizePayoutInfoUrl() {
      return this.isIncludesPrize
        ? this.registerFlags.prize.prizePayoutInfoUrl
        : '';
    },

    tournament() {
      return this.getTournament(this.id);
    },

    isTeamFFA() {
      const codeTeamFFA = 6;
      return this.tournament.idSystem === codeTeamFFA;
    },

    isRegOpen() {
      return this.tournament.status.code === 'regopen';
    },

    showPremiumBanner() {
      return (
        this.tournament?.premium &&
        this.tournament?.status?.code !== 'finished' &&
        this.tournament?.status?.code !== 'cancelled'
      );
    },

    participationErrorText() {
      const { status } = this.participationStatus || {};

      if (status === 'excluded') {
        return 'participation.teamDeclined';
      }

      if (status === 'disqualified') {
        return 'participation.disqualified';
      }

      return '';
    },

    needCheckIn() {
      return this.tournament?.restrictions?.needCheckIn;
    },

    confirmationAvailable() {
      return (
        this.needCheckIn &&
        ['regclosed', 'confirmation', 'preparation'].includes(
          this.tournament?.status?.code,
        ) &&
        !this.missedConfirmation &&
        !this.teamNotFormedError &&
        this.participationStatus.isRegistered
      );
    },

    missedConfirmation() {
      const confirmationTimedOut =
        this.tournament.status.code === 'preparation' &&
        this.tournament.countdownDate < this.timestamp;

      if (this.tournament?.canChangeTeamAfterRegister) {
        return false;
      }

      return (
        (confirmationTimedOut &&
          this.participationStatus.isRegistered) ||
        (this.tournament.status.code === 'executing' &&
          this.needCheckIn &&
          this.participationStatus.isRegistered)
      );
    },

    teamNotFormedError() {
      return this.participationStatus?.teamNotFormed || false;
    },

    canRegister() {
      return (
        !this.participationStatus &&
        (this.isRegOpen ||
          (!this.isRegOpen && this.registerFlags.canRegister))
      );
    },

    participationStatus() {
      const { participationInfo, status } =
        this.tournament.currentUserStatus || {};

      if (!participationInfo) {
        return undefined;
      }

      return {
        ...participationInfo?.participationInfo,
        status,
        isRegistered: status === 'registered',
      };
    },

    closestMatch() {
      return this.participationStatus?.closestMatch;
    },

    completedMatch() {
      return this.participationStatus?.completedMatch;
    },

    enemyTeam() {
      return this.closestMatch?.team || {};
    },

    isTeamCaptain() {
      return this.team?.isCaptain;
    },

    matchMakingVisible() {
      return (
        this.tournament.system.code === 'rating' &&
        (this.ratingSystem?.canFindMatch ||
          this.ratingSystem?.waitMatch)
      );
    },

    ratingSystem() {
      const ratingSystem = this.participationStatus?.ratingSystem;

      if (!ratingSystem) {
        return undefined;
      }

      const { stat } = { ...ratingSystem };

      const avgTime = (() => {
        if (this.mmStatsText || !stat || !stat.avgTime) {
          return 0;
        }
        return formatDuration(0, stat.avgTime).asArray.join(' ');
      })();

      return {
        ...ratingSystem,
        stat,
        avgTime,
      };
    },

    mmQueueStartTime() {
      const startWait =
        this.ratingSystem.startWait || this.mmQueueJoinTime;
      return Math.trunc(+new Date(startWait) / 1000);
    },
  },
  watch: {
    'closestMatch.dateTs': {
      handler() {
        const now = new Date().getTime() / 1000;
        if (this.closestMatch.dateTs > now) {
          this.timerIsOut = false;
        }
      },
    },
    'tournament.currentUserStatus.status': {
      handler() {
        this.team = this?.tournament.currentUserStatus?.team;
        this.$store.commit('teams/UPDATE_TEAMS', [this.team]);
      },
      deep: true,
      immediate: true,
    },
  },
  // закоменчено в рамках задачи ALLIANCE-53581
  // не удалять без подтверждения тестировщиков что нет сайд эффектов
  // created() {
  // this.fetchProfileTournamentsStatus();
  // },
  mounted() {
    if (this.isLoggedIn) {
      this.getTournamentChannels();
    }
    this.refreshRegisterInfo();
  },
  methods: {
    ...mapActions('tournamentRegistration', ['startRegistration']),
    ...mapActions('profile', ['fetchProfileTournamentsStatus']),

    hideClosestMatchBlock() {
      this.timerIsOut = true;
    },

    refreshRegisterInfo() {
      this.loadingState.registerData = true;
      return api
        .get(`/tournament/${this.id}/me`)
        .then(({ registerInfo, ...flags }) => {
          this.registerInfo = registerInfo;
          this.needPremium = registerInfo?.errorsProfileFields?.some(
            (item) => item.field === 'premium',
          );
          this.registerFlags = flags;
        })
        .finally(() => {
          this.loadingState.registerData = false;
        });
    },

    confirmRegistration() {
      this.loadingState.confirm = true;

      api
        .post(`/tournament/${this.id}/confirm`, {
          teamHash: this.team?.hash,
        })
        .then(() => {
          this.fetchProfileTournamentsStatus().finally(() => {
            this.loadingState.confirm = false;
          });
        });
    },

    cancelRegistration() {
      this.loadingState.cancel = true;

      api
        .post(`/tournament/${this.id}/deny`, {
          team_hash: this.tournament?.currentUserStatus?.team?.hash,
        })
        .finally(() => {
          this.fetchProfileTournamentsStatus().finally(() => {
            this.loadingState.cancel = false;
          });
        });
    },

    getTournamentChannels() {
      api
        .get(`/tournament/${this.id}/channels`)
        .then(({ channels }) => {
          if (channels?.length) {
            const statusChannel = channels.find(
              (channel) => channel.type === 'status',
            );
            const mmChannel = channels.find(
              (channel) => channel.type === 'matchmaking',
            );

            if (statusChannel) {
              socket.subscribe(statusChannel.name, (data) => {
                const type = _.get(data, 'data.event.type', null);

                if (type === 'tn.rating.matchmaking.status') {
                  const stats = _.get(
                    data,
                    'data.event.data.info',
                    null,
                  );

                  if (stats) {
                    this.mmStatsText = stats;
                  }
                }

                if (type === 'tn.rating.matchmaking.complete') {
                  this.mmMatchId = _.get(
                    data,
                    'data.event.data.idMatch',
                    null,
                  );
                }
              });

              socket.subscribe(mmChannel.name, (data) => {
                const type = _.get(data, 'data.event.type', null);

                if (type === 'tn.rating.matchmaking.complete') {
                  this.mmMatchId = _.get(
                    data,
                    'data.event.data.idMatch',
                    null,
                  );
                }

                if (type === 'tn.rating.matchmaking.finish') {
                  this.fetchProfileTournamentsStatus().finally(() => {
                    this.mmMatchId = null;
                    this.mmQueueJoinTime = null;
                  });
                }
              });
            }
          }
        });
    },

    joinMatchQueue() {
      this.loadingState.queue = true;

      api
        .post(`/tournament/${this.id}/matchmaking/find`)
        .then(({ task }) => {
          this.mmQueueJoinTime = task.startWait;
        })
        .finally(() => {
          this.fetchProfileTournamentsStatus().finally(() => {
            this.loadingState.queue = false;
          });
        });
    },

    leaveMatchQueue() {
      this.loadingState.queue = true;

      api
        .post(`/tournament/${this.id}/matchmaking/cancel`)
        .finally(() => {
          this.fetchProfileTournamentsStatus().finally(() => {
            this.loadingState.queue = false;
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.participation-info {
  .pvp-info-box + .participation-controls,
  .closest-match + .participation-controls {
    margin-top: 20px;
  }

  .participation-controls {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  .team-link {
    font-weight: bold;
  }

  .closest-match-title {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .closest-match {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(black, 0.2);
    font-size: 15px;

    .versus {
      margin-right: 12px;
      font-size: 17px;
      color: rgba(white, 0.3);
      font-weight: bold;
    }

    .time-left {
      font-size: 14px;
      color: rgba(white, 0.4);
    }
  }

  .team-link {
    ::v-deep {
      .team-name {
        color: $dodger-blue;
      }
    }

    &.team {
      margin-right: 12px;
    }

    &.enemy-team {
      margin-right: 12px;
    }
  }

  .matchmaking {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    .block {
      background: rgba(black, 0.1);

      &.queue-timer {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;

        .timer-text {
          font-size: 12px;
          color: rgba(white, 0.5);
        }

        .time-left {
          font-size: 40px;
        }
      }

      &.queue-controls {
        padding: 16px 40px;

        .queue-controls-text {
          text-align: center;
          margin-bottom: 12px;
        }
      }

      &.queue-stats {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 12px;
      }
    }

    .block + .block {
      margin-top: 12px;
    }
  }
}

.premium {
  margin-bottom: 15px;
}

.prize-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 24px;
  background-color: #16a7ff;
  color: white;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: background 0.2s;

  &:hover {
    color: inherit;
    background: #1981cc;
    text-decoration: none;
  }
}
</style>
