<template>
  <pvp-modal
    :value="value"
    width="580"
    class="full-width"
    @input="$emit('input', false)"
  >
    <template slot="modal-title">
      <div class="penalty-title">
        {{ $t('global.penalties') }}
        <BaseTeamLink v-if="info.isTeam" :hash="info.hash" />
        <BaseUserLink v-else :hash="info.hash" />
      </div>
    </template>
    <div class="penalty-list">
      <div
        v-for="(item, key) in info.list"
        :key="key"
        class="penalty-item"
      >
        <div class="title">{{ item.description }}</div>
        <div class="value">{{ item.points | filterFloat(5) }}</div>
      </div>
    </div>
  </pvp-modal>
</template>

<script>
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';

export default {
  name: 'PenaltyPopup',
  components: {
    BaseTeamLink,
    BaseUserLink,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.penalty {
  &-title {
    display: flex;
    align-items: center;
    justify-content: center;

    .user-link,
    .team-link {
      margin-left: 20px;
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    font-size: 17px;

    &:nth-last-of-type(odd) {
      background-color: rgba(black, 0.1);
    }

    .title {
      padding-right: 10px;
    }

    .value {
      flex-shrink: 0;
    }
  }
}
</style>
