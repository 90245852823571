<template>
  <box v-if="rules.length" class="infobox rules">
    <div class="infobox-icon">
      <div class="icon">
        <icon name="info" />
      </div>
    </div>
    <ul class="infobox-text list">
      <li v-for="(rule, idx) in rules" :key="idx">
        {{ rule.phrase }}
      </li>
    </ul>
  </box>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'RulesHub',
  components: {
    Icon,
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.infobox-text {
  font-size: 15px;
  margin-left: 40px;
  color: rgb(176, 176, 176);

  @include min-tablet() {
    font-size: 17px;
  }
}
</style>
