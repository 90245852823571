<template>
  <div class="participation-confirm">
    <ul class="participation-confirm__list">
      <li
        v-for="participant in participants"
        :key="participant.playerId"
        class="participation-confirm__item"
      >
        <ConfirmIcon :checked="participant.hasCheckedIn" />
      </li>
    </ul>
  </div>
</template>

<script>
import ConfirmIcon from './ConfirmIcon.vue';

export default {
  name: 'ParticipationConfirm',
  components: {
    ConfirmIcon,
  },
  props: {
    participants: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.participation-confirm__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
</style>
