<template>
  <div
    :class="hasPenalty ? 'adaptive' : 'rubber'"
    class="pvp-table stripped rating-table"
  >
    <div class="table-header">
      <div class="table-row">
        <cell-header t="places.single" />
        <cell-header
          :t="`ratings.table_${isTeam ? 'team' : 'player'}`"
        />
        <cell-header t="ratings.single" />
        <cell-header v-if="hasPenalty" t="global.penalties" />
      </div>
    </div>

    <div class="table-body">
      <div
        v-for="(rowData, index) in places"
        :key="index"
        class="table-row"
        :class="{ active: index === activeIndex }"
      >
        <cell-simple t="places.single" :value="rowData.place" />

        <BaseTeamLinkWithoutStore
          v-if="isTeam"
          :data="{
            hash: rowData.hash,
            name: rowData.name,
            avatarUrl: rowData.image,
          }"
        />
        <BaseUserLinkWithoutStore
          v-else
          :data="{
            hash: rowData.hash,
            name: rowData.name,
            avatarUrl: rowData.image,
          }"
        />

        <cell-simple t="ratings.single" :value="rowData.finedValue" />

        <cell-simple
          v-if="hasPenalty"
          t="global.penalties"
          class="penalty"
        >
          {{ (rowData.value - rowData.finedValue) | filterFloat(5) }}
          <pvp-btn
            v-if="rowData.penaltyDescription"
            icon-left="info"
            variant="clear"
            @click="showPenaltyPopup(rowData)"
          />
        </cell-simple>
      </div>
    </div>
    <penalty-popup v-model="isOpen" :info="penaltyInfo" />
  </div>
</template>

<script>
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import PenaltyPopup from '@components/RatingComponents/PenaltyPopup.vue';
import BaseTeamLinkWithoutStore from '@components/BaseComponents/BaseTeamLinkWithoutStore.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';

export default {
  name: 'RatingTable',
  components: {
    PenaltyPopup,
    CellSimple,
    CellHeader,
    BaseTeamLinkWithoutStore,
    BaseUserLinkWithoutStore,
  },
  props: {
    rating: {
      type: Object,
      required: true,
    },

    activeIndex: {
      type: Number,
      default: null,
    },

    hasPenalty: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    penaltyInfo: {},
  }),
  computed: {
    places() {
      return this.rating.places;
    },

    isTeam() {
      return this.rating?.type === 2;
    },
  },
  methods: {
    showPenaltyPopup(item) {
      this.penaltyInfo = {
        hash: item.hash,
        isTeam: this.isTeam,
        list: item.penalties,
      };
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-table {
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  &.adaptive {
    .table {
      &-header {
        @include max-laptop() {
          display: none;
        }
      }

      &-row {
        @include min-laptop() {
          display: grid;
          grid-template-columns: 90px 1fr 100px 120px;
        }
      }

      &-column {
        @include max-laptop() {
          ::v-deep .column-title {
            flex: 0 0 85px;
          }
        }
      }
    }
  }

  &.rubber {
    .table {
      &-column {
        ::v-deep .column-title {
          display: none;
        }
      }

      &-row {
        display: grid;
        grid-template-columns: 90px 1fr 100px;
      }
    }
  }
}

.table {
  &-row.active {
    &,
    ::v-deep .user-link,
    ::v-deep .team-link {
      font-weight: bold;
      color: $azure;
    }
  }

  &-column {
    &.penalty {
      .button {
        color: rgba(white, 0.5);
        margin-left: 4px;
      }
    }
  }
}
</style>
