<template>
  <div class="hub-header">
    <HubOrganization
      :image="organization.image"
      :title="organization.title"
      :subtitle="organization.subtitle"
      :href="organization.href"
      :is-mark="organization.mark"
    />
    <div class="hub-header__slot">
      <slot />
    </div>
  </div>
</template>

<script>
import HubOrganization from '@components/v2/hub/HubOrganization.vue';

export default {
  name: 'HubHeader',
  components: {
    HubOrganization,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-header {
  color: #ffffff;
  display: flex;
  column-gap: 12px;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;

  @include min-tablet() {
    gap: 24px;
    padding: 0 16px;
  }
  @include min-desktop() {
    padding: 0 24px;
  }
}

.hub-header__slot {
  margin-left: auto;
}
</style>
