<template>
  <HubRules :html="html" />
</template>

<script>
import HubRules from '@components/v2/hub/HubRules.vue';

export default {
  name: 'TabContentRules',
  components: { HubRules },
  props: {
    html: {
      type: String,
      default: '',
    },
  },
};
</script>
