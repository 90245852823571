<template>
  <TableRow>
    <TableHead
      v-for="(column, index) in structure"
      :key="index"
      :is-align-left="column.itemIds === 'hash'"
      :is-highlight="column.itemIds === 'prize'"
    >
      {{ column.header }}
    </TableHead>
  </TableRow>
</template>

<script>
import TableRow from '@components/v2/hub/leaderboard/table/TableRow.vue';
import TableHead from '@components/v2/hub/leaderboard/table/TableHead.vue';

export default {
  name: 'LeaderboardTableHead',
  components: {
    TableRow,
    TableHead,
  },
  props: {
    structure: {
      type: Object,
      required: true,
    },
  },
};
</script>
