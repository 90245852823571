<template>
  <Table v-if="table.results">
    <template #thead>
      <TableRow>
        <TableData heading small>
          {{ placeHeader }}
        </TableData>
        <TableData
          v-for="(heading, index) in columns"
          :key="index"
          heading
          :text-align="index ? 'center' : 'left'"
          :small="index"
        >
          {{ table.tableHeaders[heading] }}
        </TableData>
      </TableRow>
    </template>
    <template #tbody>
      <TableRow
        v-for="(row, rowIndex) in table.results"
        :key="rowIndex"
      >
        <TableData>
          <span>{{ rowIndex + 1 }}</span>
        </TableData>
        <TableData text-align="left">
          <router-link
            class="team-link"
            :to="`/team/${row.team.hash}`"
            target="_blank"
          >
            <div class="team-avatar">
              <img
                v-if="row.team.imgLogo"
                class="team-avatar__img"
                :src="row.team.imgLogo"
                alt="team avatar"
              />
              <Icon v-else name="pvp-swords" :size="32" />
            </div>
            {{ row.team.name }}
          </router-link>
        </TableData>
        <TableData
          v-for="(column, index) in columnsWithoutTeam"
          :key="index"
        >
          {{ row.data[column] }}
        </TableData>
      </TableRow>
    </template>
  </Table>
  <p v-else-if="!isLoading" class="empty-card">{{ noDataMessage }}</p>
</template>

<script>
import { i18n } from '@src/localization/config';

import Table from '@components/v2/ui/Table.vue';
import TableRow from '@components/v2/ui/TableRow.vue';
import TableData from '@components/v2/ui/TableData.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'SwissTable',
  components: {
    Table,
    TableRow,
    TableData,
    Icon,
  },

  data() {
    return {
      isLoading: true,
      table: {
        tableHeader: null,
        results: null,
      },
      columns: ['team', 'games', 'wins', 'losses', 'diff', 'points'],
      noDataMessage: i18n.t('global.noData'),
      placeHeader: i18n.t('rating.top_place'),
    };
  },

  computed: {
    columnsWithoutTeam() {
      return this.columns.slice(1);
    },
  },

  async mounted() {
    await this.getTableApi();
    this.isLoading = false;
  },

  methods: {
    async getTableApi() {
      try {
        const response = await api.get(
          `/tournament/${this.$route.params.id}/bracket/swiss/results`,
        );
        this.table = response.data;
      } catch (error) {
        throw error.message;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
}
.team-avatar {
  width: 32px;
  height: 32px;
  display: flex;

  justify-content: center;
  align-items: center;
}
.team-avatar__img {
  width: 100%;
  height: 100%;
}
.team-avatar__name {
  color: white;
  font-size: 14px;
}

.empty-card {
  padding: 32px 24px;
  background: #2d2d30;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
}
</style>
