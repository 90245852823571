<template>
  <div v-if="!isLoadingInitialData" class="tab-content-matches">
    <div class="tab-content-matches__tablist">
      <HubTabList
        v-model="currentTab"
        :tab-key="tablist.tabKey"
        :tabs="tablist.tabs"
        @input="handleTabsInput"
      />
    </div>
    <ul
      v-if="matches.length"
      ref="mainContent"
      class="tab-content-matches__list"
    >
      <li
        v-for="(match, index) in matches"
        :key="index"
        class="tab-content-matches__list-item"
      >
        <MatchCard
          :status="match.status"
          :status-text="getMatchStatusText(match.finishedAt)"
          :link="getMatchLinkString(match.id)"
          :score="{
            home: match.pointsTeamHome,
            visitor: match.pointsTeamVisitor,
          }"
          :home-team="match.teamHome"
          :visitor-team="match.teamVisitor"
        />
      </li>
    </ul>
    <HubContentMessage v-else :text="$t('global.noData')" />
  </div>
</template>

<script>
import HubContentMessage from '@components/v2/hub/HubContentMessage.vue';
import HubTabList from '@components/v2/hub/HubTabList.vue';
import MatchCard from '@components/v2/cards/MatchCard.vue';
import { MatchTabs } from './const.js';
import { getDateString } from '@utils/time.js';
import { infinityScroll } from '@utils/infinityScroll';

export default {
  name: 'TabContentMatches',
  components: {
    HubTabList,
    MatchCard,
    HubContentMessage,
  },
  data() {
    return {
      isLoadingInitialData: true,
      isLoadingMoreMatches: false,
      isAllMatches: false,
      matches: [],
      сurrentPage: 1,
      currentTab: MatchTabs[0],
    };
  },
  computed: {
    hubId() {
      return this.$route.params.id;
    },
    tablist() {
      return {
        tabKey: 'type',
        tabs: MatchTabs,
      };
    },
    matchesGetParams() {
      if (this.currentTab.type === 'self') {
        return {
          isMy: 1,
          page: this.сurrentPage,
        };
      }
      return {
        status: this.currentTab.type,
        page: this.сurrentPage,
      };
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.unSubscribeToScroll();
  },
  methods: {
    async init() {
      await this.fetchMatches();
      this.subscribeToScroll();
      this.isLoadingInitialData = false;
    },

    async fetchMatches() {
      this.response = await api.get(`hub/${this.hubId}/matches`, {
        params: this.matchesGetParams,
      });
      this.matches = this.response.data;
    },
    async fetchMoreMatches() {
      if (this.isAllMatches || this.isLoadingMoreMatches) {
        return;
      }
      this.сurrentPage += 1;
      this.isLoadingMoreMatches = true;
      this.response = await api.get(`hub/${this.hubId}/matches`, {
        params: this.matchesGetParams,
      });

      if (this.response.data.length) {
        this.isAllMatches = false;
        this.matches.push(...this.response.data);
      } else {
        this.isAllMatches = true;
      }
      this.isLoadingMoreMatches = false;
    },

    subscribeToScroll() {
      window.addEventListener('scroll', this.handleWindowScroll);
    },
    unSubscribeToScroll() {
      window.removeEventListener('scroll', this.handleWindowScroll);
    },
    resetPages() {
      this.сurrentPage = 1;
      this.isAllMatches = false;
    },

    getMatchStatusText(finishtime) {
      if (finishtime) {
        return `${this.$t(
          'tournaments.status_completed',
        )} ${getDateString(finishtime, 'ru', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })}`;
      }
      return this.$t('tournaments.matchExecuting');
    },
    getMatchLinkString(matchId) {
      return `/hub/${this.hubId}/match/${matchId}`;
    },

    handleTabsInput() {
      this.resetPages();
      this.fetchMatches();
    },
    handleWindowScroll() {
      infinityScroll(this.$refs.mainContent, this.fetchMoreMatches);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content-matches__tablist {
  margin-bottom: 16px;
}
.tab-content-matches__list {
  list-style-type: none;
}
.tab-content-matches__list-item {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.tab-content-matches__message {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  background: #2d2d30;
  padding: 20px 12px;
  border-radius: 6px;
}
</style>
