<template>
  <tr class="table-row">
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'TableHead',
};
</script>
