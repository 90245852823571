import { render, staticRenderFns } from "./HubTabList.vue?vue&type=template&id=6cb4dbe5&scoped=true"
import script from "./HubTabList.vue?vue&type=script&lang=js"
export * from "./HubTabList.vue?vue&type=script&lang=js"
import style0 from "./HubTabList.vue?vue&type=style&index=0&id=6cb4dbe5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb4dbe5",
  null
  
)

export default component.exports