var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pvp-table stripped rating-table",class:_vm.hasPenalty ? 'adaptive' : 'rubber'},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-row"},[_c('cell-header',{attrs:{"t":"places.single"}}),_c('cell-header',{attrs:{"t":`ratings.table_${_vm.isTeam ? 'team' : 'player'}`}}),_c('cell-header',{attrs:{"t":"ratings.single"}}),(_vm.hasPenalty)?_c('cell-header',{attrs:{"t":"global.penalties"}}):_vm._e()],1)]),_c('div',{staticClass:"table-body"},_vm._l((_vm.places),function(rowData,index){return _c('div',{key:index,staticClass:"table-row",class:{ active: index === _vm.activeIndex }},[_c('cell-simple',{attrs:{"t":"places.single","value":rowData.place}}),(_vm.isTeam)?_c('BaseTeamLinkWithoutStore',{attrs:{"data":{
          hash: rowData.hash,
          name: rowData.name,
          avatarUrl: rowData.image,
        }}}):_c('BaseUserLinkWithoutStore',{attrs:{"data":{
          hash: rowData.hash,
          name: rowData.name,
          avatarUrl: rowData.image,
        }}}),_c('cell-simple',{attrs:{"t":"ratings.single","value":rowData.finedValue}}),(_vm.hasPenalty)?_c('cell-simple',{staticClass:"penalty",attrs:{"t":"global.penalties"}},[_vm._v(" "+_vm._s(_vm._f("filterFloat")((rowData.value - rowData.finedValue),5))+" "),(rowData.penaltyDescription)?_c('pvp-btn',{attrs:{"icon-left":"info","variant":"clear"},on:{"click":function($event){return _vm.showPenaltyPopup(rowData)}}}):_vm._e()],1):_vm._e()],1)}),0),_c('penalty-popup',{attrs:{"info":_vm.penaltyInfo},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }