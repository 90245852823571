<template>
  <div class="hub-social-buttons">
    <div class="hub-social-buttons__title">
      {{ $t('global.share') }}
    </div>
    <div class="hub-social-buttons__main">
      <Button
        icon-only
        type="tertiary"
        size="small"
        @click="share('vk')"
      >
        <template #slotBefore>
          <Icon name="vk" group="social" />
        </template>
      </Button>
      <Button
        icon-only
        type="tertiary"
        size="small"
        @click="share('ok')"
      >
        <template #slotBefore>
          <Icon name="ok" group="social" />
        </template>
      </Button>
      <Button
        tag="a"
        :href="telegramShareHref"
        target="_blank"
        icon-only
        type="tertiary"
        size="small"
      >
        <template #slotBefore>
          <Icon name="telegram" group="social" />
        </template>
      </Button>
      <div class="hub-social-buttons__tooltip">
        <Button icon-only type="tertiary" size="small" @click="copy">
          <template #slotBefore>
            <Icon name="chain" />
          </template>
        </Button>
        <div v-if="isCopied" class="hub-social-buttons__tooltip-text">
          {{ $t('global.copied') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Button from '@components/v2/ui/Button.vue';
import { copyToClipboard } from '@utils/copyToClipboard';

export default {
  name: 'HubSocialButtons',
  components: {
    Button,
    Icon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    encodeMainLink() {
      return encodeURIComponent(window.location.href);
    },
    encodeTitle() {
      return encodeURIComponent(this.title);
    },
    encodeDescription() {
      return encodeURIComponent(this.description);
    },
    encodeImage() {
      return encodeURIComponent(this.image);
    },
    telegramShareHref() {
      return `https://t.me/share/url?url=${this.encodeMainLink}&text=${this.encodeDescription}`;
    },
  },
  methods: {
    getSocialBaseUrl(socialCode) {
      switch (socialCode) {
        case 'vk':
          return 'http://vkontakte.ru/share.php?';
        case 'ok':
          return 'https://connect.ok.ru/offer?';
        default:
          return '';
      }
    },
    share(socialCode) {
      const url = [
        [this.getSocialBaseUrl(socialCode)],
        ['url', this.encodeMainLink],
        ['title', this.encodeTitle],
        ['description', this.encodeDescription],
        ['image', this.encodeImage],
        ['noparse', true],
      ]
        .map((i) => i.join('='))
        .join('&');

      this.showPopup(url);
    },
    showPopup(url) {
      window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
    },
    copy() {
      copyToClipboard(window.location.href);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    },
  },
};
</script>

<style>
.hub-social-buttons__title {
  text-align: right;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #979797;
  text-transform: lowercase;
  margin-bottom: 2px;
}
.hub-social-buttons__main {
  display: flex;
  gap: 8px;
}
.hub-social-buttons__tooltip {
  position: relative;
}
.hub-social-buttons__tooltip-text {
  color: #979797;
  background: #1d1d1e;
  font-size: 13px;
  left: 1em;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 90%;
  transform: translateX(-50%);
}
</style>
