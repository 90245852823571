<style lang="scss" scoped>
.contacts {
  padding: 20px;

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-text {
      font-size: 18px;
      font-weight: 600;
    }

    .registration-loader {
      margin-top: 20px;
    }
  }

  .contacts-wrapper {
    @include min-laptop() {
      padding-left: 200px;
    }

    .additional {
      margin-top: 18px;
    }

    .contacts-list {
      margin-top: 18px;

      .list-item {
        display: flex;
        align-items: center;

        .item-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          $size: 27px;

          margin-right: 8px;
          width: $size;
          height: $size;

          text-align: center;
          background: rgba(black, 0.2);
          color: rgba(white, 0.7);
          border-radius: 3px;
        }
      }

      .list-item + .list-item {
        margin-top: 6px;
      }
    }
  }
}
</style>

<template>
  <box class="contacts">
    <template v-if="loadingState.page">
      <div class="loading-container">
        <div class="loading-text">{{ $t('global.loading') }}</div>
        <loader class="registration-loader" />
      </div>
    </template>
    <template v-else>
      <div class="contacts-wrapper">
        <h2>{{ $t('global.contacts') }}</h2>

        <div class="contacts-list">
          <div v-if="website" class="list-item website">
            <div class="item-icon">
              <icon name="globe" inline />
            </div>
            <a :href="website" target="_blank">{{ website }}</a>
          </div>
          <div v-if="discord" class="list-item discord">
            <div class="item-icon">
              <icon name="discord" inline />
            </div>
            <template v-if="discord.includes('https://')">
              <a :href="discord" target="_blank">{{ discord }}</a>
            </template>
            <template v-else>
              {{ discord }}
            </template>
          </div>
          <div v-if="email" class="list-item email">
            <div class="item-icon">
              <icon name="email" inline />
            </div>
            <a :href="`mailto:${email}`">{{ email }}</a>
          </div>
        </div>

        <div
          v-if="additional && additional.length"
          class="additional"
        >
          {{ additional }}
        </div>
      </div>
    </template>
  </box>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Contatcs',
  components: {
    Icon,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournamentContacts']),
    contacts() {
      return this.getTournamentContacts(this.id);
    },

    website() {
      return this.contacts.website;
    },

    discord() {
      return this.contacts.discord;
    },

    email() {
      return this.contacts.email;
    },

    additional() {
      return this.contacts.contacts;
    },
  },
  created() {
    this.fetchTournamentContacts(this.id).finally(() => {
      this.loadingState.page = false;
    });
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournamentContacts']),
  },
};
</script>
