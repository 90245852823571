<template>
  <box
    v-if="!!restrictions && showRestrictionBlock"
    class="infobox rules"
  >
    <div class="infobox-icon">
      <div class="icon">
        <icon name="info" />
      </div>
    </div>
    <ul class="infobox-text list">
      <li v-for="(rule, index) in activeBooleanRules" :key="index">
        {{ rule }}
      </li>
      <li v-if="restrictions.regionCode">
        {{ $t('restrictions.shard', { shard }) }}
      </li>
      <li v-if="restrictions.minRank">
        {{
          $t('restrictions.minRank', {
            minRank: restrictions.minRank,
          })
        }}
      </li>
      <i18n
        v-if="restrictions.needCheckIn"
        path="restrictions.needCheckIn"
        tag="li"
      >
        <b slot="start">{{ formatDate(endReg) }}</b>
        <b slot="end">{{ formatDate(start) }}</b>
      </i18n>
      <li v-if="restrictions.matchCheckIn">
        {{
          $t('restrictions.matchCheckIn', {
            time: restrictions.matchCheckInTimeout,
          })
        }}
      </li>
      <template v-if="restrictions.pickBan">
        <li>{{ $t('restrictions.pickBan') }}</li>
        <li v-if="!isHearthstone">{{ needPickBanTime }}</li>
        <li v-if="restrictions.pickBanModeDeckstrings">
          {{ $t('restrictions.deckstring') }}
        </li>
      </template>

      <template v-if="spares.isSet">
        <!-- eslint-disable vue/no-v-html -->
        <li v-html="sparesMessage"></li>
      </template>

      <li v-if="restrictions.rating">
        {{ $t('restrictions.rating') }} —
        <span
          v-for="(name, index) in restrictions.rating"
          :key="index"
        >
          {{ name }}
        </span>
      </li>
    </ul>
  </box>
</template>

<script>
import { spareCheck } from '@utils/spareChecker';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'RulesInfobox',
  components: {
    Icon,
  },
  props: {
    restrictions: {
      type: Object,
      required: true,
    },
    gameId: {
      type: Number,
      required: true,
    },
    endReg: {
      type: Number,
      required: true,
    },
    start: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('application', [
      'getWfShard',
      'getLaShard',
      'getGame',
    ]),

    game() {
      return this.getGame(this.gameId);
    },

    isHearthstone() {
      return this.game?.code === 'hs';
    },

    rules() {
      return {
        onlyOk: this.$t('restrictions.onlyOk'),
        onlyVk: this.$t('restrictions.onlyVk'),
        withCompendium: this.$t('restrictions.withCompendium'),
        needRefs: this.$t('restrictions.needRefs'),
        extendedValidation: this.$t(
          'restrictions.extendedValidation',
        ),
      };
    },

    showRestrictionBlock() {
      const {
        extendedValidation,
        matchCheckIn,
        needRefs,
        onlyOk,
        onlyVk,
        pickBan,
        withCompendium,
        regionCode,
        rating,
        minRank,
        needCheckIn,
      } = this.restrictions;
      return (
        extendedValidation ||
        matchCheckIn ||
        needRefs ||
        onlyOk ||
        onlyVk ||
        pickBan ||
        needCheckIn ||
        withCompendium ||
        !!rating ||
        !!regionCode ||
        minRank > 0
      );
    },

    shard() {
      const { regionCode } = this.restrictions;
      const shard = {
        wf: 'getWfShard',
        lar: 'getLaShard',
      }[this.game?.code];
      return this[shard]?.(Number(regionCode))?.label;
    },

    needPickBanTime() {
      return this.$t('restrictions.pickBanTime', {
        time: this.restrictions.pickBanTimeout,
      });
    },

    activeBooleanRules() {
      const active = _.reduce(
        this.restrictions,
        (acc, item, key) =>
          !!item || item > 0
            ? {
                ...acc,
                [key]: item,
              }
            : acc,
        {},
      );
      const rulesKeys = _.keys(active);
      return _.filter(this.rules, (item, key) =>
        rulesKeys.includes(key) ? item : '',
      );
    },

    spares() {
      return spareCheck(this.restrictions.maxSparePlayersCount);
    },
    sparesMessage() {
      return this.spares.value === 0
        ? `${this.spares.message}`
        : `${this.$t('restrictions.sparePlayers')} — <span>${
            this.spares.value
          }</span>`;
    },
  },
  methods: {
    formatDate(ts) {
      const now = Date.now();
      const isLastYear =
        new Date(now).getFullYear() !==
        new Date(ts * 1000).getFullYear();
      const mask = isLastYear ? 'd_MMMM_YYYY_HH_mm' : 'd_MMMM_HH_mm';
      return this.$dt(ts, mask);
    },
  },
};
</script>

<style lang="scss" scoped>
.infobox {
  margin-top: 20px;
  border: 1px solid $azure;
  border-radius: 6px;
  display: flex;
  @include min-tablet() {
    padding: 16px 20px;
  }
  @include max-tablet() {
    padding: 14px;
  }

  &-icon {
    line-height: 1;
    flex-shrink: 0;
    text-align: center;
  }

  &-text {
    margin: 0 0 0 20px;
    line-height: 1.47;
    color: rgb(176, 176, 176);
    @include min-tablet() {
      font-size: 17px;
    }
    @include max-tablet() {
      font-size: 15px;
    }

    &.list {
      padding-left: 20px;
    }
  }
}
</style>
