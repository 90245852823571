<template>
  <table class="table">
    <thead class="table__head" :class="headModifyClass">
      <slot name="thead" />
    </thead>
    <tbody class="table__body">
      <slot name="tbody" />
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Table',
  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headModifyClass() {
      return {
        'table__head--sticky': this.isSticky,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;
}
.table__head {
  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}
</style>
