import { render, staticRenderFns } from "./TabContentGeneral.vue?vue&type=template&id=06572b8d&scoped=true"
import script from "./TabContentGeneral.vue?vue&type=script&lang=js"
export * from "./TabContentGeneral.vue?vue&type=script&lang=js"
import style0 from "./TabContentGeneral.vue?vue&type=style&index=0&id=06572b8d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06572b8d",
  null
  
)

export default component.exports