<template>
  <span class="hub-state-label">
    <span v-if="loading" class="hub-state-label__loader">
      <Icon name="ring-white-gradient" group="color" inline />
    </span>
    <span class="hub-state-label__title">
      {{ title }}
    </span>
  </span>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
export default {
  name: 'HubStateLabel',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-state-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  background: #2d2d30;
  border-radius: 14px;

  @include min-tablet() {
    padding: 8px 12px;
  }
}
.hub-state-label__loader {
  animation: spin 1s linear infinite;
  line-height: 0;
}
.hub-state-label__title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;

  @include min-tablet() {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
