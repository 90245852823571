<template>
  <div class="hub-requirements">
    <template v-for="(group, groupKey) in requirements">
      <div
        v-if="group.rules.length"
        :key="groupKey"
        class="hub-requirements__section"
      >
        <div class="hub-requirements__header">
          <h3 class="hub-requirements__title">{{ group.title }}</h3>
        </div>
        <div class="hub-requirements__body">
          <div
            v-for="(rule, ruleIdx) in group.rules"
            :key="ruleIdx"
            class="hub-requirements__item"
          >
            <div class="hub-requirements__column">
              <div class="hub-requirements__icon">
                <Icon :name="mapTypes[rule.type]" :size="24" />
              </div>
            </div>
            <div class="hub-requirements__column">
              <p class="hub-requirements__item-name">
                {{ rule.title }}
              </p>
              <p class="hub-requirements__item-value">
                {{ rule.value }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'MatchMakingRequirements',
  components: { Icon },
  props: {
    requirements: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mapTypes: {
        anticheat: 'shield',
        anticheat_on: 'shield',
        anticheat_off: 'shield',
        'captainElection.random': 'crown',
        'captainPickTimeoutAction.disband': 'crossed-out-circle',
        'captainPickTimeoutAction.autopick': 'crossed-out-circle',
        'penalty.captainPickTimeout': 'crossed-out-circle',
        'penalty.checkin': 'crossed-out-circle',
        'playerPick.pick': 'user-centered',
        'playerPick.fifo': 'user-centered',
        soloAvailable: 'user-centered',
        teamAvailable: 'user-centered',
        lobbyManualCreating: 'user-centered',
        matchMakingTypeFifo: 'check',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-requirements {
  border-radius: 12px;
  background: #212222;
  padding: 16px;

  @include min-desktop() {
    padding: 24px;
  }
}
.hub-requirements__section {
  &:not(:last-child) {
    margin-bottom: 24px;

    @include min-desktop() {
      margin-bottom: 16px;
    }
  }
}
.hub-requirements__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 16px;
}
.hub-requirements__body {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
}
.hub-requirements__title {
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
.hub-requirements__item {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: #f5f5f7;
  width: 100%;

  @include min-tablet() {
    width: unset;
    min-width: calc(33.333% - 16px);
  }
}
.hub-requirements__icon {
  display: flex;
  width: 38px;
  height: 38px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #454549;
}
.hub-requirements__item-name {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.hub-requirements__item-value {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
</style>
