<template>
  <div class="players">
    <loader
      v-if="loadingState.page"
      :class="{ bg: !!teams.length }"
    />

    <template v-if="teams.length">
      <div class="players-head">
        <h4 class="players-title">
          <template v-if="singlePlayer">
            {{
              $t('tournaments.participants_players_title', {
                users: $tc(
                  'tournaments.participants_players_users',
                  total,
                ),
              })
            }}
          </template>
          <template v-else>
            {{
              $t('tournaments.participants_teams_title', {
                team: $tc(
                  'tournaments.participants_teams_team',
                  total,
                ),
              })
            }}
          </template>
        </h4>
      </div>
      <div ref="participants-list" class="players-table">
        <div class="table-row row-head">
          <div class="table-col">
            <span class="col-title">{{ $t('teams.single') }}</span>
          </div>
          <div v-if="!isVirtualTournament" class="table-col">
            <span class="col-title">{{
              $t('teams.info_country')
            }}</span>
          </div>
          <div class="table-col">
            <span class="col-title">{{
              $t('tournaments.registerDate')
            }}</span>
          </div>
          <div class="table-col">
            <span class="col-title">{{
              $t('tournaments.participants_status')
            }}</span>
          </div>
        </div>
        <template v-for="(team, index) in teams">
          <div v-if="team.hash" :key="index" class="table-row">
            <div class="table-col">
              <span class="col-title">{{ $t('teams.single') }}</span>
              <BaseTeamLink class="col-info" :hash="team.hash" />
            </div>
            <div v-if="!isVirtualTournament" class="table-col">
              <span class="col-title">{{
                $t('teams.info_country')
              }}</span>
              <country-tag
                v-if="team.idCountry"
                :id="team.idCountry"
                class="col-info"
              />
            </div>
            <div class="table-col">
              <span class="col-title">{{
                $t('tournaments.registerDate')
              }}</span>
              <span class="col-info reg">{{
                $dt(team.tsRegistered, 'ddd_dd_MMMM_HH_mm')
              }}</span>
            </div>
            <div class="table-col">
              <span class="col-title">{{
                $t('tournaments.participants_status')
              }}</span>
              <span
                class="col-info status"
                :class="team.participationStatus.activity_state"
              >
                {{ team.participationStatus.text }}
              </span>
            </div>
          </div>
        </template>
      </div>
      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        @onchange="setPage"
      />
    </template>
    <template v-else-if="!loadingState.page">
      <p class="players-desc">
        {{ $t('tournaments.placeholders_participants') }}
      </p>
    </template>
  </div>
</template>

<script>
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  name: 'Players',
  components: {
    BaseTeamLink,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      teams: [],
      // Pagination
      currentPage: 1,
      perPage: 10,
      total: 0,
      perPageSizes: [10, 30, 60],
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('tournaments', ['getTournament']),

    tournament() {
      return this.getTournament(this.id);
    },

    isVirtualTournament() {
      return this.tournament?.isVirtual;
    },

    singlePlayer() {
      return this.tournament.numTeamMembers === 1;
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    isVisible() {
      return this.teams.length;
    },
  },
  watch: {
    paginationParams: {
      handler() {
        this.scrollToElement({
          element: _.get(this.$refs, 'participants-list'),
          offset: -200,
        });
      },
    },
  },
  created() {
    this.reloadTeams();
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournamentTeams']),

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.reloadTeams(page);
    },

    reloadTeams(page = null) {
      if (page !== null) {
        this.currentPage = page;
      }

      this.loadingState.page = true;

      this.loadTeams();
    },

    loadTeams: function () {
      this.fetchTournamentTeams({
        id: this.id,
        page: this.currentPage,
        perPage: this.perPage,
      })
        .then(({ teams, pagination }) => {
          this.teams = teams.map((team) => this.getTeam(team));

          this.total = pagination.total;
        })
        .finally(() => {
          this.loadingState.page = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.players {
  min-height: 280px;
  position: relative;

  &-head {
    margin-top: 40px;
    @include min-laptop() {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &-title {
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 0;
    line-height: 1;
    @include min-laptop() {
      flex-shrink: 0;
      font-size: 24px;
    }
    @include max-laptop() {
      font-size: 18px;
    }
    @include max-tablet() {
      padding: 0 12px;
    }
  }

  &-table {
    margin-top: 18px;

    .table {
      &-row {
        @include min-laptop() {
          display: flex;
          padding-left: 32px;
          padding-right: 32px;
          &:not(.row-head) {
            &:nth-of-type(odd) {
              background: rgba($dark-two, 0.4);
            }

            .table-col {
              padding: 12px 0;
            }

            .col-title {
              display: none;
            }
          }
        }
        @include max-laptop() {
          padding: 20px 0 20px 0;
          &:nth-of-type(even) {
            background: rgba($dark-two, 0.4);
          }
          padding-left: 12px;
        }

        &.row-head {
          @include min-laptop() {
            box-shadow: 0 2px 12px 0 rgba(black, 0.11);
            background-color: rgba($dark-two, 0.41);
            padding-top: 10px;
            padding-bottom: 10px;
          }
          @include max-laptop() {
            display: none;
          }
        }
      }

      &-col {
        line-height: 1;

        @include max-laptop() {
          display: flex;
          align-items: center;
          & + .table-col {
            margin-top: 15px;
          }
        }

        @include min-laptop() {
          flex-grow: 1;
          align-self: center;

          &:nth-of-type(1) {
            width: 25%;
          }

          &:nth-of-type(2) {
            width: 22%;
          }

          &:nth-of-type(3) {
            width: 23%;
          }
          &:nth-of-type(4) {
            width: 20%;
          }
        }

        .col {
          &-title {
            font-size: 14px;
            color: rgba(white, 0.4);
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 16px;
            @include max-laptop() {
              width: 100px;
            }
          }

          &-info {
            &.team-link {
              @include max-laptop() {
                ::v-deep .team {
                  &-icon {
                    display: none;
                  }

                  &-name {
                    margin-left: 0;
                  }
                }
              }
            }

            &.reg {
              font-size: 15px;
              color: rgba(white, 0.4);
            }

            &.status {
              font-size: 14px;
              font-weight: bold;
              display: flex;
              align-items: baseline;
              line-height: 1.4;

              &.waiting {
                color: $gold;

                &:before {
                  background-color: $gold;
                }
              }

              &.active {
                color: $dark-pastel-green;

                &:before {
                  background-color: $dark-pastel-green;
                }
              }

              &.blocked {
                color: rgba(white, 0.4);

                &:before {
                  background-color: rgba(white, 0.4);
                }
              }

              &.stopped {
                color: $orangey-red;

                &:before {
                  background-color: $orangey-red;
                }
              }

              &:before {
                content: ' ';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: white;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  &-desc {
    text-align: center;
    margin-top: 20px;
  }
}

.search {
  margin: 0;
  position: relative;
  @include min-laptop() {
    width: 298px;
  }
  @include max-laptop() {
    width: 100%;
    margin-top: 20px;
  }
  @include max-tablet() {
    padding: 0 12px;
  }

  .icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    fill: rgba(white, 0.38);
    @include min-tablet() {
      left: 12px;
    }
    @include max-tablet() {
      left: 24;
    }
  }

  &-input {
    border-radius: 3px;
    background-color: rgba(black, 0.1);
    width: 100%;
    border: 1px solid rgba(white, 0.1);
    padding-left: 30px;
    height: 32px;
    box-sizing: border-box;
    font-size: 14px;
    color: white;
    color: rgba(white, 0.38);
  }
}

.pagination {
  margin-top: 20px;
}

.loader {
  margin: 20px auto;

  &.bg {
    position: absolute;
    inset: 0;
    z-index: 4;
    padding-top: 100px;
    background-color: rgba($dark, 0.8);
  }
}
</style>
