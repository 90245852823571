<template>
  <main-layout :is-loading="loadingState.page">
    <div
      v-if="tournament.isExist"
      :key="`${tournament.id}-head`"
      class="tournament-bg"
      :class="{ branded: tournament.isBranded }"
    >
      <div class="gradient" :class="{ visible: tournament.bgImage }">
        <img class="tournament-bg-image" :src="tournament.bgImage" />
        <div class="bottom" />
      </div>
      <a
        v-if="tournament.backgroundLinkUrl"
        :href="tournament.backgroundLinkUrl"
        target="_blank"
        class="tournament-background-link"
      />
    </div>

    <div v-if="isCsGame" class="tournament-notification">
      <Notification
        type="info"
        horizontal
        :title="$t('tournaments.csgo_is_working')"
        :subtitle="$t('tournaments.csgo_instruction_how_to_launch')"
        :buttons="[
          {
            text: $t('tournaments.instruction'),
            value: 'instruction',
          },
        ]"
        @click="redirectToInstruction"
      />
    </div>

    <div
      v-if="tournament.isExist"
      :key="tournament.id"
      class="tournament"
    >
      <div v-if="canEditTournament" class="tournament-edit-controls">
        <router-link
          class="tournament-edit-controls__btn"
          :to="{
            name: 'tournament-edit',
            params: { tnId: tournament.id, orgId: organization.id },
          }"
        >
          <Icon name="settings" :size="16" />
        </router-link>
      </div>
      <div
        class="pvp-container head"
        :class="{
          short: !tournament.isCountDownVisible,
          branded: tournament.isBranded,
        }"
      >
        <div
          class="game-logo"
          :class="{
            visible: tournament.canShowProfileLogo,
            big: !tournament.canShowProfileName,
          }"
        >
          <img v-if="tournament.logo" :src="tournament.logo" alt="" />
          <game-icon
            v-else
            class="game-icon"
            :name="tournament.gameCode"
            inline
          />
        </div>

        <h1 v-if="tournament.canShowProfileName" class="title">
          {{ tournament.name }}
        </h1>
        <CountdownFlip
          v-if="tournament.isCountDownVisible"
          class="countdown"
          :class="{
            padded:
              !tournament.canShowProfileLogo &&
              !tournament.canShowProfileName,
          }"
          :deadline="tournament.countDown.ts"
        >
          <p class="countdown-title">
            {{ tournament.countDown.text }}:
          </p>
        </CountdownFlip>
      </div>

      <div class="about">
        <div class="pvp-container">
          <div v-if="!isHub" class="about-box">
            <pvp-tooltip class="about-title">
              <span>{{ $t('tournaments.state_announced') }}</span>
              <div slot="tooltip" class="info-tooltip">
                {{ $t('tournaments.tooltips_announced') }}
              </div>
            </pvp-tooltip>

            <span class="about-text">
              <span class="nowrap">{{
                tournament.announcementDate
              }}</span>
            </span>
            <div class="progress-state"><span class="state" /></div>
          </div>

          <div
            v-if="!isHub"
            class="about-box"
            :class="{
              active: tournament.registrationTime.progress < 100,
            }"
          >
            <pvp-tooltip class="about-title">
              <span>{{ $t('global.register') }}</span>
              <div slot="tooltip" class="info-tooltip">
                {{ $t('tournaments.tooltips_registration') }}
              </div>
            </pvp-tooltip>
            <span class="about-text">
              <span class="nowrap"
                >{{ tournament.registrationTime.start }} -
              </span>
              <span class="nowrap">{{
                tournament.registrationTime.end
              }}</span>
            </span>
            <div class="progress-state">
              <span
                class="state"
                :style="{
                  width: `${tournament.registrationTime.progress}%`,
                }"
              />
            </div>
          </div>

          <div
            v-if="!isHub"
            class="about-box"
            :class="{
              active: tournament.executionTime.progress < 100,
            }"
          >
            <pvp-tooltip class="about-title">
              <span>{{ $t('tournaments.holding') }}</span>
              <div slot="tooltip" class="info-tooltip">
                {{ $t('tournaments.tooltips_holding') }}
              </div>
            </pvp-tooltip>
            <span class="about-text">
              <span class="nowrap"
                >{{ tournament.executionTime.start }} -
              </span>
              <span class="nowrap">{{
                tournament.executionTime.end
              }}</span>
            </span>
            <div class="progress-state">
              <span
                class="state"
                :style="{
                  width: `${tournament.executionTime.progress}%`,
                }"
              />
            </div>
          </div>

          <div
            class="about-box"
            :class="{ 'about-box--flex-hub': isHub }"
          >
            <span class="about-title">{{ $t('games.single') }}</span>
            <router-link
              class="about-router-link"
              :to="{
                path: '/tournaments',
                query: { games: game.id },
              }"
              @click="gameTagClickHandler"
            >
              <game-tag
                :code="tournament.gameCode"
                full-name
                class="about-text"
                :class="{ 'about--about-text-white-color': isHub }"
              />
            </router-link>
          </div>

          <div class="about-box">
            <pvp-tooltip :class="{ 'about-box--flex-hub': isHub }">
              <span class="about-title">{{
                $t('tournaments.system')
              }}</span>
              <span
                class="about-text"
                :class="{ 'about--about-text-white-color': isHub }"
                >{{ tournamentFormatText }}</span
              >
              <div slot="tooltip" class="info-tooltip">
                {{ tournamentFormatTooltip }}
              </div>
            </pvp-tooltip>
          </div>

          <div v-if="!isHub" class="about-box">
            <pvp-tooltip>
              <span class="about-title">{{
                $t('participants.few')
              }}</span>
              <span class="about-text">
                {{ tournament.participantsCountStatistic }}
              </span>
              <div slot="tooltip" class="info-tooltip">
                {{ $t('tournaments.tooltips_participants') }}
              </div>
            </pvp-tooltip>
          </div>

          <div
            v-if="organization.id"
            class="about-box organization"
            :class="{ 'organization--hub': isHub }"
          >
            <pvp-tooltip>
              <span class="about-title">{{
                $t('organizations.organizer')
              }}</span>
              <div slot="tooltip" class="info-tooltip">
                {{ $t('tournaments.tooltips_organization') }}
              </div>
            </pvp-tooltip>
            <OrganizationLink
              :id="organization.id"
              class="about-text link"
              :class="{ 'about--about-link-blue-color': isHub }"
              :display-image="false"
            />
          </div>
        </div>
      </div>

      <div class="pvp-container router-wrapper">
        <div class="tournament__tab-list">
          <TabList
            :tabs="tabs"
            :start-tab-index="startTabIndex"
            @change-tab="tabInputHadler"
          />
        </div>
        <router-view
          :id="tournament.id"
          :game-id="game.id"
          :hub="hub"
          :is-hub="isHub"
          :loading="loadingState"
          :participant-state="participantState"
          @account:linked="handleAcountLinkSuccess"
        />
      </div>
    </div>

    <!--  -->
    <!--  -->
    <!--  -->
    <Modal v-if="isShowCheckinModal" type="regular">
      <PlayersCheckin
        :participants="participantState.lobby.participants"
        :action="participantState.lobby.participant.action"
        :time="checkinTime"
        :rules="lobbyRules"
        @confirm="handleConfirmCheckin"
        @cancel="handleCancelCheckin"
      />
    </Modal>
    <!--  -->
    <!--  -->
    <!--  -->

    <template slot="seo-text">
      <p>{{ seoText }}</p>
    </template>
  </main-layout>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import { spareCheck } from '@utils/spareChecker';
import CountdownFlip from '@components/BaseComponents/CountdownFlip/CountdownFlip.vue';
import TabList from '@components/v2/TabList.vue';
import Modal from '@components/v2/Modal.vue';
import PlayersCheckin from '@components/Matchmaking/PlayersCheckin.vue';
import Notification from '@components/v2/ui/Notification.vue';
import Icon from '@components/v2/utils/Icon.vue';
import OrganizationLink from '@components/BaseComponents/OrganizationLink.vue';

export default {
  name: 'TournamentPage',
  components: {
    CountdownFlip,
    TabList,
    Modal,
    PlayersCheckin,
    Notification,
    Icon,
    OrganizationLink,
  },

  beforeRouteUpdate(to, from, next) {
    if (this.id !== to.params.id) {
      this.fetchData(to.params.id);
    }
    next();
  },

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  page() {
    return {
      title:
        this.tournament?.meta?.title || this.$t('tournaments.single'),
      meta:
        [{ description: this.tournament?.meta?.description }] ||
        this.$t('tournaments.single'),
    };
  },

  data: () => ({
    tnId: null,
    sliderIds: [],
    tournamentTabs: [],
    hub: {},
    participantState: {},
    loadingState: {
      isLoadingStartQueue: false,
      isLoadingFinishQueue: false,
    },
  }),
  computed: {
    ...mapGetters('profile', [
      'getHubUpdateKey',
      'getIsUpdatedUserProfile',
      'isLoggedIn',
    ]),
    ...mapGetters('tournaments', [
      'getTournamentPage',
      'getTournament',
    ]),
    ...mapGetters('organizations', ['getOrganization']),
    ...mapGetters('application', ['getGameCode', 'getGameByCode']),

    gameCode() {
      return this.getGameCode(this.tournament?.idGame);
    },

    isHub() {
      const idSystem = this.getTournament(this.id).idSystem;
      return idSystem === 9;
    },

    lobbyRules() {
      return this.participantState.lobby?.rules;
    },

    seoText() {
      return this.$t('seo.tournament', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },

    checkinTime() {
      return this.participantState.lobby.checkinTimeoutTs;
    },

    tabs() {
      if (this.isHub) {
        return [
          {
            title: this.$t('tournaments.overview'),
            link: { name: 'tournament', params: { id: this.tnId } },
          },
          {
            title: this.$t('matches.few'),
            link: {
              name: 'tournament-schedule',
              params: { id: this.tnId },
            },
          },
          {
            title: this.$t('tournaments.leader_board'),
            link: {
              name: 'tournament-table',
              params: { id: this.tnId },
            },
          },
          {
            title: this.$t('rules.title'),
            link: {
              name: 'tournament-rules',
              params: { id: this.tnId },
            },
          },
          {
            title: this.$t('global.contacts'),
            link: {
              name: 'tournament-contacts',
              params: { id: this.tnId },
            },
          },
        ];
      }
      const tabsData = {
        info: this.$t('tournaments.overview'),
        bracket: this.$t('tournaments.bracket'),
        players: this.$t('participants.few'),
        rules: this.$t('rules.title'),
        participation: this.$t('tournaments.participation'),
        schedule: this.$t('tournaments.schedule'),
        video: this.$t('video.few'),
        news: this.$t('news.few'),
        contacts: this.$t('global.contacts'),
        table: this.$t('tournaments.table'),
      };

      const { systemCode } = this.tournament || {};
      return (this.tournamentTabs || []).reduce((result, key) => {
        let title = tabsData[key];
        let name =
          key === 'info' ? 'tournament' : `tournament-${key}`;

        if (
          key === 'bracket' &&
          ['rating', 'subgroups', 'swiss'].includes(systemCode)
        ) {
          title = tabsData.table;

          if (systemCode === 'rating') {
            name = 'tournament-table';
          }
        }

        if (title) {
          result.push({
            title,
            link: { ...this.tournament?.link, name },
          });
        }

        return result;
      }, []);
    },

    startTabIndex() {
      return (
        this.tabs.findIndex(
          (e) => e.link.name === this.$route.name,
        ) || 0
      );
    },

    tournament() {
      return this.getTournamentPage(this.tnId);
    },

    game() {
      return this.getGameByCode(this.tournament.gameCode);
    },

    isCsGame() {
      const csgoGameId = 24;
      const cs2Code = 'cs2';
      return this.gameId === csgoGameId || this.gameCode === cs2Code;
    },

    organization() {
      return (
        this.getOrganization(this.tournament.idOrganization) || {}
      );
    },

    canEditTournament() {
      return this.organization?.abilities?.canEditTournament;
    },

    spares() {
      return spareCheck(this.tournament.maxReservePlayersCount);
    },

    tournamentFormatText() {
      const formatText = this.tournament.formatInfo.text;
      const spareText =
        this.spares.isSet && this.spares.value > 0
          ? `(${this.spares.value})`
          : '';

      return `${formatText} ${spareText}`;
    },

    tournamentFormatTooltip() {
      const formatTooltip = this.tournament.formatInfo.tooltip;
      const spareTooltip = this.spares.isSet
        ? this.spares.message
        : '';

      return `${formatTooltip} ${spareTooltip}`;
    },

    lobbyId() {
      return this.participantState?.lobby.id || null;
    },

    isShowCheckinModal() {
      return this.participantState.lobby?.status === 'checkin';
    },
  },

  watch: {
    id() {
      this.init();
    },

    async getHubUpdateKey() {
      await this.fetchParticipantData();

      if (this.participantState?.status === 'match') {
        this.redirectToCurrentMatch();
        return;
      }
      if (
        this.participantState?.status === 'lobby' &&
        this.participantState?.lobby?.status === 'pick'
      ) {
        this.redirectToCurrentLobby();
        return;
      }
    },
    async getIsUpdatedUserProfile() {
      await this.fetchParticipantData();
    },
    isShowCheckinModal(value) {
      if (value) {
        this.playSound();
      }
    },
  },

  created() {
    this.init();
  },

  mounted() {
    window.addEventListener(
      'visibilitychange',
      this.handleFetchParticipantData,
    );
  },

  beforeDestroy() {
    window.removeEventListener(
      'visibilitychange',
      this.handleFetchParticipantData,
    );
  },

  methods: {
    ...mapActions('tournaments', ['fetchTournamentPage']),
    ...mapActions('organizations', ['fetchOrganization']),
    ...mapActions('tournamentSettings', ['fetchTournamentFields']),

    async init() {
      this.loadingState.page = true;
      await this.fetchData(this.id);
      if (this.isHub) {
        await this.fetchHubData();
        if (this.isLoggedIn) {
          await this.fetchParticipantData();
        }
        if (
          this.participantState?.status === 'lobby' &&
          this.participantState?.lobby?.status === 'pick'
        ) {
          this.redirectToCurrentLobby();
          return;
        }

        this.subscribeToHabChannels();
      }
      this.loadingState.page = false;
    },

    playSound() {
      const audio = new Audio('/audio/alert.mp3');
      if (audio) {
        audio.volume = 0.3;
        audio.play();
      }
    },

    async fetchData(id) {
      try {
        const tournamentPageResponse = await this.fetchTournamentPage(
          id,
        );
        this.tnId = tournamentPageResponse.tournament?.id;
        this.tournamentTabs = tournamentPageResponse.tabs;
        await this.fetchOrganization(this.organization.id);
      } catch (error) {
        this.$router.push({
          name: 'not-found',
        });
      }

      window.scrollTo({
        top: 0,
        behaviour: 'smooth',
      });
    },

    async fetchHubData() {
      this.hub = (await api.get(`hub/${this.id}`)).data;
    },

    async fetchParticipantData() {
      this.loadingState.isLoadingStartQueue = true;
      this.participantState = (
        await api.get(`hub/${this.id}/participant/state`)
      ).data;
      this.loadingState.isLoadingStartQueue = false;
    },

    handleFetchParticipantData() {
      if (this.isLoggedIn && !document.hidden && this.isHub) {
        this.fetchParticipantData();
      }
    },

    subscribeToHabChannels() {
      this.hub?.wsChannels.forEach((channel) => {
        socket.subscribe(channel.name, async ({ data }) => {
          switch (data.event.type) {
            case 'hub.updated': {
              await this.fetchHubData();
              break;
            }
          }
        });
      });
    },

    redirectToCurrentLobby() {
      this.$router.push({
        name: 'hub-match',
        params: {
          id: this.id,
          matchId: 'new',
          tournament: this.tournament,
        },
      });
    },
    redirectToCurrentMatch() {
      this.$router.push({
        path: `/match/${this.participantState.match.id}`,
      });
    },

    handleAcountLinkSuccess() {
      this.fetchParticipantData();
    },
    async handleConfirmCheckin() {
      try {
        return await api.post(
          `hub/${this.id}/lobby/${this.lobbyId}/checkin`,
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async handleCancelCheckin() {
      try {
        return await api.delete(
          `hub/${this.id}/lobby/${this.lobbyId}/checkin`,
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },

    gameTagClickHandler() {
      // ======================================================
      // METRICS Турниры. Переход на страницу "Всех турниров" и примененным фильтром игры
      const tmrParams = {
        category: 'pvp',
        action: 'tourn_game_click',
        label: this.game.name,
        url: window.location.href,
      };
      pushEvents('tourn_game_click', tmrParams);
      // ======================================================
    },
    tabInputHadler(data) {
      if (data.currentTab.link !== undefined) {
        this.$router.push({
          ...data.currentTab.link,
          query: this.$route.query,
        });
      }
      // ======================================================
      // METRICS Турниры. Клик по элементам на странице турнира
      const tmrParams = {
        category: 'pvp',
        action: 'tourn_click',
        label: data.currentTab.title,
        url: window.location.href,
      };
      pushEvents('pvp_tourn_click', tmrParams);
      // ======================================================
    },
    redirectToInstruction() {
      window.open(
        'https://support.vkplay.ru/pvp/steam/4361',
        '_blank',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.game-icon {
  font-size: 108px;
}

.tournament {
  position: relative;
  color: white;
  /* min-width: map_get($containerSize, xs); */

  &-bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1920px;
    overflow: hidden;
    max-height: 600px;

    &-image {
      display: none;

      @include min-laptop() {
        width: 100%;
        display: block;
      }
    }

    &.branded {
      .tournament-bg-image {
        max-height: 100%;
      }

      &:after {
        background: none;
      }
    }

    .gradient {
      &.visible {
        &:after,
        &:before {
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 35%;
          height: 100%;
        }

        &:after {
          right: 0;
          background: linear-gradient(
            to right,
            transparent,
            rgba(#121415, 0.2) 50%,
            #121415
          );
        }
        &:before {
          left: 0;
          background: linear-gradient(
            to left,
            transparent,
            rgba(#121415, 0.2) 50%,
            #121415
          );
        }

        .bottom {
          position: absolute;
          inset: 0;
          background: linear-gradient(
            to bottom,
            transparent,
            rgba(#121415, 0.75) 60%,
            #121415
          );
        }
      }
    }

    .tournament-background-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.box {
  margin-top: 20px;
}

.head {
  padding: 24px 0;
  text-align: center;
  @include min-laptop() {
    padding: 100px 0;
    min-height: 277px;
    &.short {
      min-height: 167px;
    }
  }
  @include max-laptop() {
    min-height: 148px;
  }
}

.title {
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 0;
  @include max-tablet {
    font-size: 20px;
  }
  @include min-tablet {
    font-size: 26px;
    text-align: center;
  }
}

.countdown {
  &.padded {
    margin-top: 75px;
  }

  @include max-tablet {
    display: none;
  }
  @include min-tablet {
    margin-top: 20px;
    text-align: center;

    ::v-deep .flip-clock-text,
    &-title {
      font-size: 12px;
      color: #d4d4d4;
      margin-bottom: 8px;
    }

    ::v-deep .flip-clock-text {
      text-transform: lowercase;
    }

    .flip-clock {
      margin-top: 6px;
    }
  }
}

.about {
  position: relative;
  @include max-laptop() {
    min-width: 320px;
    background: rgba($dark-two, 0.4);
    .not-mobile {
      display: none;
    }
  }
  @include min-laptop() {
    text-align: center;
  }

  .pvp-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @include max-laptop() {
      /* max-width: map_get($containerSize, sm); */
    }
    @include min-laptop() {
      justify-content: center;
      align-items: flex-end;
    }
  }

  &-box {
    position: relative;
    flex-shrink: 0;
    margin-top: 13px;

    @include max-laptop() {
      width: 100%;
      &:nth-of-type(odd) {
        max-width: 45%;
      }

      &:nth-of-type(even) {
        max-width: 50%;
        margin-left: auto;
      }
    }

    &:nth-of-type(-n + 3) {
      &.active {
        .about {
          &-title,
          &-text {
            color: white;
          }
        }
      }

      .about {
        &-title {
          display: block;
        }

        &-text {
          color: rgba(white, 0.3);
          margin: 0;
        }
      }

      @include min-laptop() {
        width: 32.3%;

        &:not(:first-of-type) {
          margin-left: 1%;
        }
      }
    }

    &:nth-of-type(n + 4) {
      @include min-laptop() {
        margin: 24px 20px 0;
      }
    }

    .progress-state {
      @include max-laptop() {
        display: none;
      }
      height: 4px;
      border-radius: 2px;
      background: rgba(white, 0.2);
      margin-top: 10px;

      .state {
        display: block;
        height: 100%;
        background: $azure;
        position: relative;
        border-radius: 2px;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: rgba(white, 0.3);
    @include max-laptop() {
      display: block;
      font-size: 12px;
    }
  }

  &-text {
    color: white;
    line-height: 1.4;
    margin-top: 1px;

    &.link {
      color: $azure;
    }

    @include max-laptop() {
      font-size: 13px;
      .nowrap {
        display: block;
      }
    }
    @include min-laptop() {
      margin-left: 10px;
      font-size: 15px;
    }
  }

  &-link {
    font-size: 12px;
    color: $azure;

    @include min-desktop() {
      position: absolute;
      right: 0;
    }
    @include min-laptop() {
      margin-left: 10px;
      font-size: 15px;
    }

    @include max-laptop() {
      position: relative;
      width: 100%;
      display: block;
      margin-top: 12px;
    }
  }
}

.game {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  &-logo {
    max-width: 424px;
    height: 108px;
    margin: 0 auto;
    color: rgba(white, 0.8);

    &.visible {
      svg,
      img {
        display: inline-block;
        vertical-align: top;
      }

      &.big {
        height: 155px;
      }
    }

    svg,
    img {
      display: none;
      max-width: 100%;
      max-height: 100%;
    }

    @include max-tablet {
      height: 84px;
    }
  }

  &-box {
    &:not(:first-of-type) {
      margin-left: 40px;
    }
  }
}

.router-wrapper {
  position: relative;
  /* min-width: map_get($containerSize, xs); */
  @include max-tablet() {
    padding: 0;
  }
}

.tabs-menu {
  position: relative;
}

.organization {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .trusted {
    margin-left: 8px;
    @include max-laptop() {
      margin-right: 8px;
    }
  }
}

.organization--hub {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.about-router-link {
  &:hover {
    text-decoration: none;
  }
}

.tournament-edit-controls {
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 12px 0;

  @include max-laptop() {
    padding: 12px;
  }
}
.tournament-edit-controls__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d4d4d4;
  padding: 8px;
  background: #2d2d30;
  border: 1px solid rgba(245, 245, 247, 0.12);
  border-radius: 8px;

  &:hover {
    color: white;
  }
}
.tournament__tab-list {
  padding: 32px 0 0;
  margin-bottom: 32px;

  @include max-laptop() {
    padding: 8px 0 0;
  }
}
.about-box--flex-hub {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.about {
  &--about-text-white-color {
    color: white !important;
  }
  &--about-link-blue-color {
    color: #16a7ff !important;
  }
}
.tournament-notification {
  position: relative;
  z-index: 100;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
