<template>
  <box class="rules">
    <!-- eslint-disable vue/no-v-html -->
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <wysiwyg-viewer
      v-if="rules"
      class="rules-wrapper"
      v-html="rules.replaceAll(';amp', '').replace('&amp;', '&')"
    />
  </box>
</template>

<script>
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';

export default {
  components: { WysiwygViewer },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournamentRules']),
    rules() {
      return this.getTournamentRules(this.id);
    },
  },
  created() {
    this.fetchRules();
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournamentRules']),
    fetchRules() {
      if (!this.rules) {
        this.fetchTournamentRules(this.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rules {
  padding: 28px 0;

  &-wrapper {
    max-width: 800px + 2 * 24;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    @include min-laptop() {
      padding: 0 24;
    }
    @include max-laptop() {
      padding: 0 12px;
    }
  }
}
</style>
