<template>
  <component
    :is="tournamentComponent"
    v-if="!loading"
    :id="id"
    :tournament-data="tournamentData"
  />
</template>

<script>
import HubPage from './Hub/HubPage';
import TournamentPage from '@views/Tournament/TournamentPage.vue';

export default {
  name: 'Tournament',
  components: {
    HubPage,
    TournamentPage,
  },
  data() {
    return {
      tournamentData: null,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    // isHub() {
    //   return this.tournamentData?.tournament.idSystem === 9;
    // },
    tournamentComponent() {
      // if (this.isHub) {
      //   return 'HubPage';
      // }
      return 'TournamentPage';
    },
  },
  // FIXIT Пока фриз на хабы убираем этот дублирующий запрос на /tournament
  // watch: {
  //   id() {
  //     this.init();
  //   },
  // },
  // created() {
  //   this.init();
  // },
  // methods: {
  //   async init() {
  //     this.tournamentData = await api.get(`tournament/${this.id}`);
  //     this.loading = false;
  //   },
  // },
};
</script>
