<template>
  <div ref="bracket" class="bracket-wrapper">
    <div class="bracket-logo">
      <Icon name="watermark-dark" group="color" :size="[208, 54]" />
    </div>
    <table class="bracket-table">
      <tr>
        <td>Название турнира</td>
        <td>{{ tnDetails.tnName }}</td>
      </tr>
      <tr>
        <td>Игра</td>
        <td>{{ tnDetails.game }}</td>
      </tr>
      <tr>
        <td>Формат</td>
        <td>{{ getTnFormatString }}</td>
      </tr>
      <tr>
        <td>Участники</td>
        <td>{{ getParticipantsString }}</td>
      </tr>
      <tr>
        <td>Организатор</td>
        <td>{{ tnDetails.organization }}</td>
      </tr>
    </table>
    <div class="bracket-view">
      <component
        :is="bracketType"
        v-if="bracketType"
        :tournament-id="id"
        :rounds="rounds"
      />
    </div>
  </div>
</template>

<script>
import Single from '@components/TournamentComponents/Brackets/SingleElimination.vue';
import Double from '@components/TournamentComponents/Brackets/DoubleElimination.vue';
import GroupStageTable from '@components/TournamentComponents/Brackets/GroupStageTable.vue';
import TeamFFA from '@components/TournamentComponents/Brackets/TeamFFA.vue';
import SwissTable from '@components/TournamentComponents/Brackets/SwissTable';
import Icon from '@components/v2/utils/Icon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'BracketPrint',
  components: {
    Single,
    Double,
    TeamFFA,
    GroupStageTable,
    SwissTable,
    Icon,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        bracket: true,
      },
      bracketType: '',
      bracketName: '',
      tnDetails: {
        tnName: '',
        game: '',
        format: '',
        organization: '',
        participants: {
          active: '',
          limit: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters('tournaments', [
      'getTournament',
      'getTournamentBracket',
    ]),
    ...mapGetters('application', ['getGame']),

    tournament() {
      return this.getTournament(this.id);
    },
    system() {
      return this.tournament?.system?.code;
    },
    rounds() {
      return this.getTournamentBracket(this.id) || [];
    },
    hasBracket() {
      if (
        (_.isArray(this.rounds) && !this.rounds.length) ||
        (this.system === 'double_elim' &&
          !this.rounds.winners.length &&
          !this.rounds.loosers.length)
      ) {
        return false;
      }
      return true;
    },
    getTnFormatString() {
      return `${this.bracketName}, ${this.tnDetails.format}x${this.tnDetails.format}`;
    },
    getParticipantsString() {
      return `${this.tnDetails.participants.active}/${this.tnDetails.participants.limit}`;
    },
  },
  async mounted() {
    const { tournament, organization } =
      await this.fetchTournamentPage(this.id);
    await this.fetchTournamentBracket(this.id).finally(() => {
      this.loadingState.bracket = false;
      this.selectBracket();
    });

    this.tnDetails.tnName = tournament.name;
    this.tnDetails.game = this.getGame(tournament.idGame).shortName;
    this.tnDetails.format = tournament.numTeamMembers;
    this.tnDetails.participants.active =
      tournament.numActiveParticipants;
    this.tnDetails.participants.limit =
      tournament.numLimitParticipants;
    this.tnDetails.organization = organization.name;

    document.addEventListener('click', handler, true);

    function handler(e) {
      e.stopPropagation();
      e.preventDefault();
    }
  },
  methods: {
    ...mapActions('tournaments', [
      'fetchTournamentPage',
      'fetchTournamentBracket',
    ]),

    selectBracket() {
      switch (this.system) {
        case 'single':
          this.bracketType = 'Single';
          this.bracketName = 'Олимпийская система';
          break;
        case 'double_elim':
          this.bracketType = 'Double';
          this.bracketName = 'Выбывание после 2-х поражений';
          break;
        case 'team_ffa':
          this.bracketType = 'TeamFFA';
          this.bracketName = 'Система на выбывание';
          break;
        case 'swiss':
          this.bracketType = 'SwissTable';
          break;
        case 'subgroups':
          this.bracketType = 'GroupStageTable';
          break;
        default:
          throw new Error('No matching bracket types found');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bracket-wrapper {
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  height: 100%;
  max-height: 100%;
  min-height: 100vh;
}
.bracket {
  &-view {
    display: flex;
    flex-direction: column;
  }
  &-match {
    background-color: #000;
  }
}

.loader {
  margin: 20px auto;
}

.error {
  text-align: center;
  margin-top: 40px;
}

.bracket-onfo__details {
  color: #000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 24px;
}

.bracket-logo {
  display: block;
  margin-top: 30px;
  margin-bottom: 12px;
}

::v-deep {
  .slide {
    background-color: #121213;
    color: #fff;

    &:not(.empry) {
      background-color: #121213;
      color: #fff;
    }
  }
  .bracket-match {
    background-color: #121213;
    color: #fff;
    &::before,
    &::after {
      background-color: #121213;
    }
  }
  .bracket-row:not(:last-child) .line {
    color: #000;
  }
  .bracket-row:not(:last-child) .bracket-col:after {
    background-color: #121213;
  }
  .se-bracket-header,
  .de-bracket-header,
  .ffa-bracket-header {
    visibility: hidden;
    height: 0;
  }
  .se-nav,
  .de-nav,
  .tffa-nav,
  .order {
    display: none;
  }
}

.bracket-table {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border-collapse: collapse;

  td {
    border: 2px solid #000;
    padding: 4px 12px;
  }
}
</style>
