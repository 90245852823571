<template>
  <!-- TODO переписать на новую динамическую таблицу -->
  <div class="subgroups">
    <div class="subroups-header">
      <tabs-menu
        v-model="currentTab"
        variant="secondary"
        :list="tabs"
      />
      <bracket-search
        :placeholder="$t('tournaments.tableSearch')"
        :tournament-id="tournamentId"
        @input="afterBracketSearch"
      />
    </div>
    <div
      :class="{ penalty: hasPenalty }"
      class="pvp-table stripped adaptive"
    >
      <div class="table-header">
        <div class="table-row" :class="{ short: onlyPoints }">
          <cell-header t="teams.single" />

          <template v-if="!onlyPoints">
            <cell-header t="games.few" />
            <cell-header t="matches.results_wins" />
            <cell-header t="matches.results_draws" />
            <cell-header t="matches.results_defeats" />
            <cell-header t="matches.results_diff" />
          </template>

          <cell-header t="scores.few" />
          <cell-header v-if="hasPenalty" t="global.penalties" />
        </div>
      </div>

      <div ref="box" class="table-body">
        <div
          v-for="(item, index) in currentGroup"
          :key="index"
          :class="{
            active: item.team.hash === activeTeamHash,
            short: onlyPoints,
          }"
          class="table-row"
        >
          <cell-team :hash="item.team.hash" />

          <template v-if="!onlyPoints">
            <cell-simple
              t="games.few"
              :value="item.data.matchesPlayed"
            />
            <cell-simple
              t="matches.results_wins"
              :value="item.data.wins"
            />
            <cell-simple
              t="matches.results_draws"
              :value="item.data.draws"
            />
            <cell-simple
              t="matches.results_defeats"
              :value="item.data.losses"
            />
            <cell-simple
              t="matches.results_diff"
              :value="item.data.diff"
            />
            <cell-simple
              t="scores.few"
              :value="item.data.finedPoints"
            />
          </template>
          <cell-simple
            v-else
            t="scores.few"
            :value="item.data.points"
          />

          <cell-simple
            v-if="hasPenalty"
            t="global.penalties"
            class="penalty"
          >
            {{
              (item.data.points - item.data.finedPoints)
                | filterFloat(5)
            }}
            <pvp-btn
              v-if="item.data.penaltyDescription"
              icon-left="info"
              variant="clear"
              @click="showPenaltyPopup(item)"
            />
          </cell-simple>
        </div>
      </div>
    </div>
    <penalty-popup v-model="isOpen" :info="penaltyInfo" />
  </div>
</template>

<script>
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellTeam from '@components/TableCells/CellTeam.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import BracketSearch from '@components/TournamentComponents/Brackets/BracketUI/BracketSearch.vue';
import PenaltyPopup from '@components/RatingComponents/PenaltyPopup.vue';

export default {
  name: 'GroupStageTable',
  components: {
    CellHeader,
    CellTeam,
    CellSimple,
    BracketSearch,
    PenaltyPopup,
  },
  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
    rounds: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    activeTeamHash: '',
    currentTab: 0,
    isOpen: false,
    penaltyInfo: {},
  }),
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapState('application', ['scssContext']),
    onlyPoints() {
      return Object.keys(this.currentGroup[0].data).length === 1;
    },
    hasPenalty() {
      return this.getTournament(this.tournamentId)?.hasPenalty;
    },
    tabs() {
      return this.rounds?.groups?.map(({ name }) => ({
        title: name,
      }));
    },
    currentGroup() {
      return this.rounds?.groups?.[this.currentTab]?.teams;
    },
  },
  methods: {
    afterBracketSearch(teamHash) {
      this.activeTeamHash = teamHash;
      const checkTeam = ({ team }) => team.hash === teamHash;
      const tabIndex = this.rounds.findIndex(
        ({ teams }) => teams.findIndex(checkTeam) !== -1,
      );
      if (tabIndex !== -1) {
        this.currentTab = tabIndex;
        this.$nextTick(() => {
          const box = this.$refs.box.querySelector(
            '.table-row.active',
          );
          if (box) {
            const { headerHeight } = this.scssContext;
            window.scrollTo({
              top:
                window.pageYOffset +
                box.getBoundingClientRect().top -
                headerHeight,
            });
          }
        });
      }
    },
    showPenaltyPopup(item) {
      this.penaltyInfo = {
        hash: item.team.hash,
        isTeam: true,
        list: item.data.penalties,
      };
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.subroups-header {
  margin-top: 30px;
  @include min-laptop() {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include max-tablet() {
    padding-left: 12px;
    padding-right: 12px;
  }

  .bracket-search {
    z-index: 3;
    @include min-laptop() {
      margin-left: 10px;
    }
    @include max-laptop() {
      margin-top: 1em;
    }
  }

  .tabs-menu {
    text-align: left;
    @include max-tablet() {
      margin-left: -12px;
      margin-right: -12px;
    }
  }
}

.pvp-table {
  margin-top: 20px;

  &.penalty {
    .table-row {
      @include min-laptop() {
        grid-template-columns: 4fr repeat(7, 1fr);
      }
    }
  }

  .table-row {
    display: grid;
    @include min-laptop() {
      grid-template-columns: 4fr repeat(6, 1fr);
    }

    &.short {
      @include min-laptop() {
        grid-template-columns: 6fr 1fr;
      }
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }

    .table-column {
      padding-top: 10px;
      padding-bottom: 10px;
      color: rgba(white, 0.42);
    }
  }

  &-column {
    padding-left: 10px;
    padding-right: 10px;
    @include max-laptop() {
      ::v-deep .column-title {
        flex: 0 0 85px;
      }
    }

    &.penalty {
      .button {
        color: rgba(white, 0.5);
        margin-left: 4px;
      }
    }
  }

  &-row {
    &.active {
      ::v-deep .team-link {
        color: $azure;
        font-weight: bold;
      }
    }
  }
}
</style>
