import { i18n } from '@src/localization/config';

export const MainTabs = [
  {
    title: i18n.t('actions.overview'),
    type: 'general',
  },
  {
    title: i18n.t('match.matches'),
    type: 'matches',
  },
  {
    title: i18n.t('tournaments.leader_board'),
    type: 'leaderboard',
  },
  {
    title: i18n.t('menu.rules'),
    type: 'rules',
  },
];

export const MatchTabs = [
  {
    id: 1,
    title: i18n.t('tournaments.tabs_current'),
    type: 'match',
  },
  {
    id: 2,
    title: i18n.t('tournaments.tabs_completed'),
    type: 'finished',
  },
  { id: 3, title: i18n.t('matches.my'), type: 'self' },
];

export const LeaderboardTabs = [
  { id: 1, title: i18n.t('main.general'), type: 'default' },
  { id: 2, title: i18n.t('main.current'), type: 'current' },
  {
    id: 3,
    title: i18n.t('tournaments.tabs_completed'),
    type: 'finished',
  },
];
