<template>
  <pvp-tooltip class="trusted" :class="{ enabled: isTrusted }">
    <Icon :name="isTrusted ? 'trusted' : 'untrusted'" />
    <template slot="tooltip">{{ tooltip }}</template>
  </pvp-tooltip>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TrustedIcon',
  components: {
    Icon,
  },
  props: {
    isTrusted: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'tournament',
    },
  },
  computed: {
    tooltip() {
      switch (this.type) {
        case 'organization':
          return this.$t(
            `organizations.trusted_${
              this.isTrusted ? 'enabled' : 'disabled'
            }`,
            {
              host: window.location.host.toLocaleUpperCase(),
            },
          );
        case 'tournament':
          return this.$t(
            `tournaments.tooltips_trusted_${
              this.isTrusted ? 'enabled' : 'disabled'
            }`,
            {
              host: window.location.host.toLocaleUpperCase(),
            },
          );
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trusted {
  display: inline-flex;
  color: $burnt-red;

  &.enabled {
    color: $dark-pastel-green;
  }

  .icon {
    width: 15px;
    height: 16px;
  }
}
</style>
