<template>
  <div class="tab-content-general">
    <div class="tab-content-general__params">
      <HubRequirements :requirements="requirements" />
    </div>
    <div class="tab-content-general__band">
      <Admins
        :title="$t('main.admins')"
        :button-text="$t('actions.show_more')"
        :discord="discord"
        :admins="admins"
      />
    </div>
  </div>
</template>

<script>
import HubRequirements from '@components/v2/hub/HubRequirements.vue';
import Admins from '@components/v2/Admins.vue';

export default {
  name: 'TabContentGeneral',
  components: {
    HubRequirements,
    Admins,
  },
  props: {
    discord: {
      type: String,
      default: '',
    },
    requirements: {
      type: Object,
      default: () => {},
    },
    admins: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';
.tab-content-general {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include min-desktop() {
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  }
}
.tab-content-general__params {
  width: 100%;
}
.tab-content-general__band {
  @include min-desktop() {
    min-width: 340px;
  }
}
</style>
