<template>
  <div class="hub-quick-match">
    <div class="hub-quick-match__content">
      <div v-if="isShowLabels" class="hub-quick-match__labels">
        <HubStateLabel
          v-if="playersLabel"
          :title="playersLabel"
          loading
        />
        <HubStateLabel v-if="matchesLabel" :title="matchesLabel" />
      </div>
      <div class="hub-quick-match__tournament">
        <h1 class="hub-quick-match__title">{{ title }}</h1>
        <div class="hub-quick-match__subtitle">
          <div class="hub-quick-match__icon">
            <Icon :name="gameCode" group="games" :size="16" />
          </div>
          {{ format }}
        </div>
      </div>
    </div>
    <div v-if="isShowButton" class="hub-quick-match__action">
      <div class="hub-quick-match__button">
        <Button
          :text="buttonText"
          type="primary"
          size="big"
          long
          :disabled="disabled"
          @click="handleButtonClick"
        />
      </div>
      <div
        v-for="(warning, index) in warnings"
        :key="index"
        class="hub-quick-match__warning"
      >
        <span class="hub-quick-match__warning-icon">
          <Icon name="triangle-warning" inline />
        </span>
        <span class="hub-quick-match__warning-text">
          {{ warning.phrase }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import Button from '@components/v2/ui/Button.vue';
import HubStateLabel from '@components/v2/hub/HubStateLabel.vue';
export default {
  name: 'HubQuickMatch',
  components: {
    Button,
    Icon,
    HubStateLabel,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    gameCode: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: true,
    },
    playersLabel: {
      type: String,
      default: '',
    },
    matchesLabel: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      required: true,
    },
    warnings: {
      type: Array,
      default: () => [],
    },
    isShowButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowLabels() {
      return this.playersLabel || this.matchesLabel;
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-quick-match {
  padding: 12px;
  border-radius: 12px;
  color: #ffffff;
  background: #1d1d1e;

  @include min-tablet() {
    padding: 16px;
  }
  @include min-desktop() {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.hub-quick-match__content {
  &:not(:last-child) {
    margin-bottom: 16px;

    @include min-tablet() {
      margin-bottom: 24px;
    }
    @include min-desktop() {
      margin-bottom: 0px;
    }
  }
}

.hub-quick-match__labels {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;

  @include min-tablet() {
    margin-bottom: 24px;
  }
}
.hub-quick-match__title {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 8px;

  @include min-tablet() {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  @include min-desktop() {
    font-size: 32px;
    line-height: 28px;
  }
}
.hub-quick-match__subtitle {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;

  @include min-tablet() {
    font-size: 18px;
    line-height: 22px;
  }
}
.hub-quick-match__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(245, 245, 247, 0.12);
  background: linear-gradient(180deg, #2c2d2e 0%, #0e0f0f 100%);
}
.hub-quick-match__action {
  @include min-desktop() {
    width: 272px;
  }
}
.hub-quick-match__button {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.hub-quick-match__warning {
  display: flex;
  gap: 8px;
  color: #e06e0b;
}

.hub-quick-match__warning-icon {
  font-size: 14px;
  line-height: 1em;

  @include min-desktop() {
    font-size: 16px;
  }
}

.hub-quick-match__warning-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.3px;

  @include min-desktop() {
    font-size: 16px;
  }
}
</style>
