<template>
  <BaseLink
    :to="{ name: 'team', params: { hash: data.hash } }"
    :title="$t('teams.linkTooltip', { name: data.name })"
    class="team-link"
    :class="{ reversed: isReversed }"
    target="_blank"
  >
    <team-avatar
      v-if="displayImage"
      :src="data.avatarUrl"
      :style="iconStyle"
      class="team-icon"
    />
    <div v-if="displayName" class="team-name">
      <slot name="teamname">{{ data.name }}</slot>
      <div v-if="$slots.teamgame" class="teamgame">
        <slot name="teamgame" />
      </div>
      <div v-if="$slots.teamsize" class="teamsize">
        <slot name="teamsize" />
      </div>
    </div>
  </BaseLink>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'BaseTeamLinkWithoutStore',
  components: {
    BaseLink,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    displayName: {
      type: Boolean,
      default: true,
    },
    displayImage: {
      type: Boolean,
      default: true,
    },
    isReversed: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    nameFirstLetter() {
      return this.data.name.slice(0, 1);
    },
    iconStyle() {
      return {
        width: `${this.imageSize}px`,
        height: `${this.imageSize}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.team-link {
  color: white;
  min-width: 0;
  display: inline-flex;
  align-items: center;

  &,
  &:hover {
    text-decoration: none;
  }

  .team-icon {
    flex-shrink: 0;

    & + .team-name {
      margin-left: 1em;
    }
  }

  .team-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
  }

  &.full {
    text-align: center;
    display: block;

    .team {
      &-icon {
        margin: 0 auto;
        font-size: 60px;

        & + .team-name {
          margin: 16px 0 0;
        }
      }

      &-name {
        font-size: 20px;
        margin: 0;
      }
    }
  }

  &.reversed {
    justify-content: flex-end;

    .team-icon {
      order: 2;
      margin-left: 1em;
    }

    .team-icon + .team-name {
      margin-left: 0;
      text-align: right;
    }
  }
}

.teamgame,
.teamsize {
  opacity: 0.7;
  font-size: 13px;
  margin-top: 2px;
}
</style>
