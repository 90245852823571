<template>
  <BaseLink
    v-if="organization"
    :to="{ name: 'organization', params: { id: organization.id } }"
    :title="
      $t('organizations.linkTooltip', { name: organization.name })
    "
    class="organization-link"
    :class="{ reversed: isReversed }"
  >
    <template v-if="displayImage">
      <organization-avatar
        :src="organization.image"
        class="organization-icon"
      />
    </template>
    <div v-if="displayName" class="organization-name">
      {{ organization.name }}
    </div>
    <span class="trust-icon">
      <TrustedIcon
        v-if="organization.isTrust"
        :is-trusted="organization.isTrust"
        type="organization"
      />
    </span>
  </BaseLink>
</template>

<script>
import TrustedIcon from '@components/BaseComponents/TrustedIcon';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'OrganizationLink',
  components: {
    TrustedIcon,
    BaseLink,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    displayName: {
      type: Boolean,
      default: true,
    },
    displayImage: {
      type: Boolean,
      default: true,
    },
    isReversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('organizations', ['getOrganization']),

    organization() {
      return this.getOrganization(this.id);
    },
    nameFirstLetter() {
      return this.organization.name.slice(0, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
$image-size: 32px;
$image-full-size: 120px;

.organization-link {
  color: white;
  min-width: 0;
  display: inline-flex;
  align-items: center;

  &,
  &:hover {
    text-decoration: none;
  }

  .organization-icon,
  .organization-icon.icon {
    min-width: $image-size;
    width: $image-size;
    height: $image-size;
    flex-shrink: 0;
  }

  .organization-name {
    min-width: 0;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1em;
  }

  &.full {
    text-align: center;
    display: block;

    .organization {
      &-icon {
        margin: 0 auto;
        width: $image-full-size;
        height: $image-full-size;
        line-height: $image-full-size;
        font-size: 60px;

        & + .organization-name {
          margin: 16px 0 0;
        }
      }

      &-name {
        font-size: 20px;
        margin: 0;
      }
    }
  }

  &.reversed {
    justify-content: flex-end;

    .organization-icon {
      order: 2;
      margin-left: 1em;
    }

    .organization-icon + .organization-name {
      margin-left: 0;
      text-align: right;
    }
  }
}

.trust-icon {
  line-height: 0;
  margin-left: 4px;
}
</style>
