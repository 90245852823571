<template>
  <td class="table-data" :class="tableDataModifyClass">
    <div class="table-data__cell" :class="cellModifyClass">
      <slot />
    </div>
  </td>
</template>

<script>
export default {
  name: 'TableData',
  props: {
    isAlignLeft: {
      type: Boolean,
      default: false,
    },
    isNowrap: {
      type: Boolean,
      default: false,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableDataModifyClass() {
      return this.isHighlight
        ? 'table-data--highlight'
        : 'table-data--default';
    },
    cellModifyClass() {
      return [
        this.isAlignLeft
          ? 'table-data__cell--left'
          : 'table-data__cell--center',
        this.isNowrap ? 'table-data__cell--nowrap' : '',
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.table-data {
  border-top: 1px solid rgba(245, 245, 247, 0.12);
  border-bottom: 1px solid rgba(245, 245, 247, 0.12);

  &:first-child {
    border-left: 1px solid rgba(245, 245, 247, 0.12);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-right: 1px solid rgba(245, 245, 247, 0.12);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &--default {
    background: #212222;
  }
  &--highlight {
    background: #2a75cb;
  }
}

.table-data__cell {
  height: 100%;
  padding: 12px;
  color: #f5f5f7;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;

  @include min-desktop() {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
  &--nowrap {
    white-space: nowrap;
  }
}
</style>
