<template>
  <main-layout>
    <div v-if="!loading" class="hub-page">
      <section class="hub-page__header">
        <HubHeader :organization="organization">
          <div class="hub-page__additional-controls">
            <Button
              v-if="isAdministrator"
              tag="router-link"
              :to="hubEditPageLink"
              type="tertiary"
              size="small"
              icon-only
            >
              <template #slotBefore>
                <Icon name="gear-wheel" :size="14" />
              </template>
            </Button>
            <HubSocialButtons
              :title="tournamentData.tournament.name"
              :image="organization.image"
            />
          </div>
        </HubHeader>
      </section>

      <section class="hub-page__match">
        <HubQuickMatch
          :title="tournamentData.tournament.name"
          :game-code="gameCode"
          :format="format"
          :players-label="playersLabel"
          :matches-label="matchesLabel"
          :button-text="quickMatchButtonText"
          :warnings="reasons"
          :disabled="isButtonDisabled"
          :is-show-button="hubData.isMatchmakingEnabled"
          @click="handleQuickMatchButtonClick"
        />
      </section>

      <section class="hub-page__tablist">
        <TabListButtons
          v-model="currentTab"
          :tabs="tablist.tabs"
          :tab-key="tablist.tabKey"
        />
      </section>

      <section class="hub-page__main">
        <component
          :is="currentComponent.name"
          v-bind="{ ...currentComponent.props }"
        />
      </section>
    </div>
  </main-layout>
</template>

<script>
import HubHeader from '@components/v2/hub/HubHeader.vue';
import HubSocialButtons from '@components/v2/hub/HubSocialButtons.vue';
import HubQuickMatch from '@components/v2/hub/HubQuickMatch.vue';
import TabListButtons from '@components/v2/TabListButtons.vue';
import TabContentGeneral from './TabContentGeneral.vue';
import TabContentMatches from './TabContentMatches.vue';
import TabContentLeaderboard from './TabContentLeaderboard.vue';
import TabContentRules from './TabContentRules.vue';
import Button from '@components/v2/ui/Button.vue';
import Icon from '@components/v2/utils/Icon.vue';
import { MainTabs } from './const.js';

export default {
  name: 'HubPage',
  components: {
    HubHeader,
    HubQuickMatch,
    TabListButtons,
    TabContentGeneral,
    TabContentMatches,
    TabContentLeaderboard,
    TabContentRules,
    HubSocialButtons,
    Button,
    Icon,
  },
  props: {
    tournamentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      currentTab: MainTabs[0],
      hubData: {},
      adminsData: {},
      currentHubParticipantState: {},
    };
  },
  computed: {
    ...mapGetters('organizations', ['getOrganization']),
    ...mapGetters('application', ['getGames']),
    ...mapGetters('matchmaking', [
      'getHubId',
      'getMatchId',
      'getStatus',
      'getIsMatchmacking',
      'getIsSearching',
      'getIsCheckin',
      'getIsMatch',
    ]),
    ...mapGetters('profile', ['hasMailAccount', 'isLoggedIn']),

    queryParams() {
      return this.$route.query;
    },
    currentComponent() {
      const components = {
        general: {
          name: 'TabContentGeneral',
          props: {
            admins: this.adminsData.items,
            requirements: this.hubData.rulesView,
            discord: this.organization.contacts.discord,
          },
        },
        matches: {
          name: 'TabContentMatches',
          props: {},
        },
        leaderboard: {
          name: 'TabContentLeaderboard',
          props: {},
        },
        rules: {
          name: 'TabContentRules',
          props: {
            html: this.tournamentData.tournament.rules,
          },
        },
      };

      return components[this.currentTab.type];
    },
    playersLabel() {
      if (this.hubData.matchmakingQueueSize) {
        return this.$t('hub.matchmakingPlayersAmount', {
          count: this.hubData.matchmakingQueueSize,
        });
      }
      return '';
    },
    matchesLabel() {
      if (this.hubData.totalActiveMatches) {
        return this.$t('hub.matchesAmount', {
          count: this.hubData.totalActiveMatches,
        });
      }
      return '';
    },
    gameCode() {
      return (
        this.getGames.find(
          (game) => game.id === this.tournamentData.tournament.idGame,
        ).code || 'oth'
      );
    },
    format() {
      return `${this.tournamentData.tournament.numTeamMembers}x${this.tournamentData.tournament.numTeamMembers}`;
    },
    hubId() {
      return this.$route.params.id;
    },
    rules() {
      return this.hubData.rules || [];
    },
    hubEditPageLink() {
      return `/organization/${this.organization.id}/tournament/${this.hubId}`;
    },
    organization() {
      if (this.tournamentData.organization) {
        const organization = this.tournamentData.organization;

        return {
          id: organization.id,
          contacts: organization.contacts,
          image: organization.imgLogo,
          title: organization.name,
          subtitle: this.$t('tournament.organizer'),
          href: '/organization/' + organization.id,
          mark: organization.isTrust,
        };
      }
      return {};
    },
    quickMatchButtonText() {
      if (!this.hubData.isRegistered) {
        return this.$t('main.joinToHub');
      }
      if (this.getIsMatch) {
        return this.$t('matches.toMatch');
      }
      if (this.isCurrentHubMatchmaking) {
        return this.$t('main.cancel_find');
      }
      return this.$t('main.find_match');
    },
    reasons() {
      if (!this.hasMailAccount) {
        return [{ phrase: this.$t('main.isAccountWarning') }];
      }
      if (!this.isLoggedIn) {
        return [{ phrase: this.$t('main.isLoggedInWarning') }];
      }

      return (
        this.currentHubParticipantState.matchmaking?.reasons || []
      );
    },
    tablist() {
      return {
        tabs: MainTabs,
        tabKey: 'title',
      };
    },

    isValidParticipant() {
      return this.isLoggedIn && this.hasMailAccount;
    },
    isCurrentHubMatchmaking() {
      return this.getHubId === Number(this.hubId);
    },
    isButtonDisabled() {
      return (
        (this.getIsMatchmacking && !this.isCurrentHubMatchmaking) ||
        Boolean(this.reasons.length)
      );
    },
    isAdministrator() {
      const organization = this.getOrganization(this.organization.id);
      return organization?.abilities?.canEditTournament;
    },
  },
  watch: {
    getStatus: function () {
      this.fetchCurrentHubParticipantState();
    },
    hubId: function () {
      this.init();
    },
    currentTab(tab) {
      this.$router.replace({
        query: { ...this.queryParams, tab: tab.type },
      });
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions('organizations', ['fetchOrganization']),
    ...mapActions('matchmaking', ['fetchMatchmakingState']),

    async init() {
      this.initTabs();

      try {
        await this.fetchHubData();
        this.subscribeToHubChannels();
        this.fetchOrganization(this.organization.id);
        await this.fetchAdminsData();
        if (this.isValidParticipant) {
          await this.fetchCurrentHubParticipantState();
        }
        this.loading = false;
      } catch (error) {
        this.$router.push({ name: 'not-found' });
      }
    },
    initTabs() {
      let currentTab = this.getCurentTabByQueryParam();
      if (!currentTab) {
        this.$router.replace({
          query: { ...this.queryParams, tab: 'general' },
        });
        currentTab = this.getCurentTabByQueryParam();
      }
      this.currentTab = currentTab;
    },
    getCurentTabByQueryParam() {
      return MainTabs.find(
        (tab) => tab?.type === this.queryParams.tab,
      );
    },

    async fetchHubData() {
      this.hubData = (await api.get(`hub/${this.hubId}`)).data;
    },
    async fetchAdminsData() {
      this.adminsData = await api.get(
        `organization/${this.organization.id}/players`,
      );
    },
    async fetchCurrentHubParticipantState() {
      this.currentHubParticipantState = (
        await api.get(`hub/${this.hubId}/participant/state`)
      ).data;
    },

    async joinHub() {
      await api.post(`hub/${this.hubId}/join`);
    },
    async leaveHub() {
      await api.delete(`hub/${this.hubId}/join`);
    },
    async toggleSearchMatch() {
      if (this.getIsMatchmacking) {
        await api.delete(`hub/${this.hubId}/register`);
        return;
      }
      await api.post(`hub/${this.hubId}/register`);
    },

    subscribeToHubChannels() {
      this.hubData.wsChannels.forEach((channel) => {
        socket.subscribe(channel.name, async ({ data }) => {
          switch (data.event.type) {
            case 'hub.updated': {
              await this.fetchHubData();
              break;
            }
          }
        });
      });
    },

    handleQuickMatchButtonClick() {
      if (!this.hubData.isRegistered) {
        this.joinHub();
        return;
      }

      if (this.getIsMatch) {
        this.$router.push(`/hub/${this.hubId}/match/new`);
        return;
      }

      this.toggleSearchMatch();
    },
  },
};
</script>

<style lang="scss" scoped>
.hub-page {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 12px;
}
.hub-page__header {
  margin-bottom: 16px;
}
.hub-page__match {
  margin-bottom: 8px;
}
.hub-page__tablist {
  margin-bottom: 16px;
}
.hub-page__additional-controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 24px;
  width: 100%;
}
</style>
