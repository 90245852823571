<template>
  <TableRow>
    <TableData
      v-for="(col, idx) in structure"
      :key="idx"
      :is-align-left="col.itemIds === 'hash'"
      :is-nowrap="col.itemIds !== 'hash'"
      :is-highlight="isHighlight"
    >
      <div
        v-if="col.itemIds === 'hash'"
        class="hub-leaderboard__user"
      >
        <HubUserAvatar :src="columns[col.itemIds].image" />
        {{ columns[col.itemIds].name }}
      </div>
      <template v-else-if="col.itemIds === 'prize'">
        <template v-if="columns[col.itemIds]">
          {{ columns[col.itemIds].value }}
          {{ columns[col.itemIds].type === 1 ? '₽' : '' }}
        </template>
      </template>
      <template v-else>{{ columns[col.itemIds] }}</template>
    </TableData>
  </TableRow>
</template>

<script>
import TableRow from '@components/v2/hub/leaderboard/table/TableRow.vue';
import TableData from '@components/v2/hub/leaderboard/table/TableData.vue';
import HubUserAvatar from '@components/v2/hub/HubUserAvatar.vue';

export default {
  name: 'LeaderboardTableRow',
  components: {
    TableRow,
    TableData,
    HubUserAvatar,
  },
  props: {
    structure: {
      type: Object,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.hub-leaderboard__user {
  min-width: 260px;
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>
