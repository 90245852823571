<template>
  <ul class="hub-tablist">
    <li
      v-for="(tab, idx) in tabs"
      :key="tab.id"
      class="hub-tablist__item"
    >
      <button
        class="hub-tablist__button"
        :class="{
          'hub-tablist__button--active': activeTabIdx === idx,
        }"
        @click="handleTabClick(idx)"
      >
        {{ tab.title }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HubTabList',
  props: {
    value: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    tabKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    activeTabIdx() {
      return this.tabs.findIndex(
        (tab) => tab[this.tabKey] === this.value[this.tabKey],
      );
    },
  },
  methods: {
    handleTabClick(index) {
      this.$emit('input', this.tabs[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.hub-tablist {
  display: inline-flex;
  padding: 4px;
  border-radius: 8px;
  background: #2d2d2d;

  @include min-tablet() {
    padding: 6px;
  }
}

.hub-tablist__item {
  list-style-type: none;
}

.hub-tablist__button {
  padding: 6px 8px;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;

  @include min-tablet() {
    font-size: 16px;
    line-height: 20px;
  }

  &:not(.hub-tablist__button--active) {
    color: #979797;
    background: #2d2d2d;

    &:hover {
      background: #f5f5f71f;
    }
  }
  &--active {
    color: #161516;
    background: #e2e3e7;
  }
}
</style>
